import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ChannelInfoVM from '@messenger/core/src/Redux/ChannelInfos/ChannelInfoVM';
import {selectChannelInfoVMById} from '@messenger/core/src/Redux/ChannelInfos/Selectors/channelInfoDefaultSelectors';
import {getChannelIdForSelector} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {getChatIdForSelector, selectChatVmById} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';
import {selectGiftEntityVMs} from '@messenger/core/src/Redux/Gifts/Selectors/defaultSelectors';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {TQueryUserModel} from '@messenger/core/src/Redux/QueryUser/TQueryUserModel';
import {webNotificationClientOnlyActions} from '@messenger/core/src/Redux/WebNotification/Actions/webNotificationClientOnlyActions';
import {WebNotificationHelper} from '@messenger/core/src/Redux/WebNotification/WebNotificationHelper';
import {TFocusable} from '@messenger/core/src/Services/AbstractUiContainer';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

import {isMessageForArchivedChannel, isOldMessageEvent} from 'src/Redux/Messages/Sagas/playSoundIfNotSpamOrMutedSaga';

/**
 * @note this Saga should process own event, but not message event
 *
 * @see playSoundIfNotSpamOrMuted
 */
const showWebNotificationWithActionsSaga = function* (
	browser: TFocusable,
	action: PayloadAction<TSswMessageType & TQueryUserModel>,
) {
	try {
		const message = action.payload;
		const isOld = isOldMessageEvent(message);
		const isSpam: boolean = yield* isMessageForArchivedChannel(message);

		if (!browser.isTabFocused() && !isOld && !isSpam) {
			const giftEntities = yield* select(selectGiftEntityVMs);
			const chat = yield* select(selectChatVmById, getChatIdForSelector(message.chatID));

			if (!chat) {
				ServiceFactory.logService.debug(`Chat not found ${message.chatID}`, {message});

				return;
			}

			const channelInfoVM: ChannelInfoVM | undefined = !_.isUndefined(message.channelId)
				? yield* select(selectChannelInfoVMById, getChannelIdForSelector(message.channelId))
				: undefined;

			const messageVM = new MessageViewModel(action.payload, giftEntities, chat.login);

			yield* put(
				webNotificationClientOnlyActions.show({
					id: WebNotificationHelper.getRandomNotificationId(),
					title: WebNotificationHelper.getNotificationText(messageVM, channelInfoVM),
					options: WebNotificationHelper.getNotificationOptions(message, [chat.chatId], channelInfoVM),
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showWebNotificationWithActionsSaga', action});
	}
};

export default showWebNotificationWithActionsSaga;
