import {takeEvery} from 'typed-redux-saga';

import {streamClientToServerActions, streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {resetCam2CamStoreSaga} from '@messenger/core/src/Redux/Cam2CamPreview/Sagas/resetCam2CamStoreSaga';
import {loadLastLiveSessionSaga} from '@messenger/core/src/Redux/LiveSessions/Sagas/loadLastLiveSessionSaga';
import processStreamingEndedSaga from '@messenger/core/src/Redux/Client/Sagas/processStreamingEndedSaga';

import processStreamingStartedSaga from 'src/Redux/Stream/Sagas/processStreamingStartedSaga';
import {disableTicketShowSaga} from 'src/Redux/TicketShow/Sagas/disableTicketShowSaga';
import {statusReceivedSaga} from 'src/Redux/Stream/Sagas/statusReceivedSaga';
import streamingGoOnlineSaga from 'src/Redux/Stream/Sagas/streamingGoOnlineSaga';
import detectSupportedMediaCodecsSaga from 'src/Redux/Stream/Sagas/detectSupportedMediaCodecsSaga';
import streamRestartReceivedSaga from 'src/Redux/Stream/Sagas/streamRestartReceivedSaga';
import startWebRtcOutputStreamSaga from 'src/Redux/Stream/Sagas/startWebRtcOutputStreamSaga';
import processGoOnlineSaga from 'src/Redux/Stream/Sagas/processGoOnlineSaga';
import streamingGoOfflineSaga from 'src/Redux/Stream/Sagas/streamingGoOfflineSaga';
import {resetSupportedCodecsSaga} from 'src/Redux/MediaDevice/Sagas/resetSupportedCodecsSaga';
import {stopPrivateTicketShowByTimerSaga} from 'src/Redux/Stream/Sagas/stopPrivateTicketShowByTimerSaga';
import {notifyOnGuestAvsSaga} from 'src/Redux/Stream/Sagas/notifyOnGuestAvsSaga';
import processGoOfflineSaga from 'src/Redux/Stream/Sagas/processGoOfflineSaga';
import startExternalToolStreamSaga from 'src/Redux/Stream/Sagas/startExternalToolStreamSaga';
import processRestartGoOnlineSaga from 'src/Redux/Stream/Sagas/processRestartGoOnlineSaga';
import setVideoFacingModeSaga from 'src/Redux/Stream/Sagas/setVideoFacingModeSaga';
import {processMaybeGoOfflineSaga} from 'src/Redux/Stream/Sagas/processMaybeGoOfflineSaga';

export default function* streamSagaWatcher() {
	yield* takeEvery(streamClientOnlyActions.startExternalToolStream, startExternalToolStreamSaga);
	yield* takeEvery(streamServerToClientActions.configReceived, streamingGoOnlineSaga);
	yield* takeEvery(streamServerToClientActions.configReceived, detectSupportedMediaCodecsSaga);
	yield* takeEvery(streamServerToClientActions.streamRestartReceived.type, streamRestartReceivedSaga);
	yield* takeEvery(streamClientOnlyActions.startWebRTCStream, startWebRtcOutputStreamSaga);
	yield* takeEvery(streamClientOnlyActions.goOnline, processGoOnlineSaga);
	yield* takeEvery(streamClientOnlyActions.maybeGoOffline, processMaybeGoOfflineSaga);
	yield* takeEvery(streamClientOnlyActions.restartGoOnline, processRestartGoOnlineSaga);
	yield* takeEvery(streamClientOnlyActions.goOffline, processGoOfflineSaga);
	yield* takeEvery(streamServerToClientActions.stopStreamReceived, streamingGoOfflineSaga);
	yield* takeEvery(streamClientOnlyActions.stopStream.type, resetSupportedCodecsSaga);
	yield* takeEvery(streamClientOnlyActions.stopStream.type, resetCam2CamStoreSaga);
	yield* takeEvery(streamServerToClientActions.startPrivateShowResponseReceived.type, stopPrivateTicketShowByTimerSaga);
	yield* takeEvery(streamClientToServerActions.startPrivateShow.type, notifyOnGuestAvsSaga);
	yield* takeEvery(streamServerToClientActions.statusReceived.type, statusReceivedSaga);
	yield* takeEvery(streamClientOnlyActions.setVideoFacingMode, setVideoFacingModeSaga);
	yield* takeEvery(streamServerToClientActions.startSendResponseReceived, processStreamingStartedSaga);
	yield* takeEvery(streamServerToClientActions.stopSendResponseReceived, processStreamingEndedSaga);
	yield* takeEvery(streamServerToClientActions.stopSendResponseReceived, disableTicketShowSaga);
	yield* takeEvery(streamServerToClientActions.stopSendResponseReceived, loadLastLiveSessionSaga);
}
