import {PayloadAction} from '@reduxjs/toolkit';
import {delay, put, select} from 'typed-redux-saga';
import {EnumMessageDirection} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import {getIsIntroRunning} from '@messenger/core/src/Redux/Introduction/Selectors';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';

import {getDoeReplyMessageStep} from 'src/Components/Introduction/StepsHelper';

const appendIntroStepToReplyMessage = function* (action: PayloadAction<TSswMessageType>) {
	try {
		const isRunning = yield* select(getIsIntroRunning);

		if (!isRunning || action.payload.direction !== EnumMessageDirection.OUT) {
			return;
		}

		const messageId = action.payload.messageId;

		if (_.isUndefined(messageId)) {
			throw new Error('messageId is undefined');
		}

		const replyStep = getDoeReplyMessageStep(ServiceFactory.i18n.t, `.intro__messages-area [data-id="${messageId}"]`);

		yield* delay(500);
		yield* put(introductionClientOnlyActions.appendStep(replyStep));
		yield* put(
			channelsClientOnlyActions.updateChannel({
				id: EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE,
				changes: {
					flags: undefined,
				},
			}),
		);
		yield* put(introductionClientOnlyActions.saveReplyMessageId(messageId));
		yield* put(
			messagesClientOnlyActions.updateIntroductionMessage({
				messageId: messageId,
				messageKey: `${messageId}_sent`,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'appendIntroStepToReplyMessage'});
	}
};

export default appendIntroStepToReplyMessage;
