import {
	EnumBooleanStringified,
	SessionState,
	EnumStreamStatusType,
	JSONString,
	UploadMediaTus,
} from 'cmd-control-client-lib';

export type TSessionState = SessionState & {
	instanceId?: string;
	isLoginSent: EnumBooleanStringified;
	isInitialized: EnumBooleanStringified;
	isLoggedIn: EnumBooleanStringified | undefined;
	supportChannelId?: string;
	showToyTab: EnumBooleanStringified;
	usrKey?: string;
	usrId?: string;
	userLinks: string;
	freeMode?: EnumBooleanStringified;
	partyMode?: EnumBooleanStringified;
	isNetworkAvailable: EnumBooleanStringified;
	foreignSID?: string;
	isTooltipStartOnlineShown: EnumBooleanStringified;
	uploadMediaTus?: JSONString<UploadMediaTus>;
};

export enum EnumPermanentSettings {
	KEYBOARD_SHORTCUTS = 'keyboardShortcuts',
	SERVER_STATUS = 'serverStatus',
	SHOWN_INFO_WIZARDS = 'shownInfoWizards',
	KEEP_SELECTED_CHANNEL_IN_VIEW = 'keepSelectedChannelInView',
}

export type TPermanentData = {
	[EnumPermanentSettings.KEYBOARD_SHORTCUTS]?: TKeyboardShortcut[];
	[EnumPermanentSettings.SERVER_STATUS]?: {
		type: EnumStreamStatusType;
		value: EnumBooleanStringified;
	};
	[EnumPermanentSettings.SHOWN_INFO_WIZARDS]?: string[];
	[EnumPermanentSettings.KEEP_SELECTED_CHANNEL_IN_VIEW]?: boolean;
};

export type TKeyboardShortcut = {
	autoSend: boolean;
	text: string;
	keyStatus: TKeyStatus;
};

export type TKeyStatus =
	| {
			altKey: boolean;
			ctrlKey: boolean;
			key: string;
			keyCode: number;
			metaKey: boolean;
			shiftKey: boolean;
	  }
	| string;
