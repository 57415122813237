import { JSONString } from "../../@types";
import { ICOMMAND, ACTION, IRESPONSE, RESULT, IKeyMaybeValue } from "../../cmd-protocol";
import { baseParamsType, SupportedLanguage } from "../command/baseparams";

export enum MSettingNameEnum {
  CONVERSION_CHAT = "ConversionChat",
  HEATMAP_ENABLED = "HeatmapEnabled",
  HEATMAP_DESCRIPTION = "HeatmapDescription",
  LIVE_CHAT_PREVIEW = "LiveChatPreview",
  LOVENSE_TOY_REQUEST = "LovenseToyRequest",
  TICKET_SHOW_REQUEST = "TicketShowRequest",
  MESSENGER_PRICE = "MessengerPrice",
  VIDEO_CHAT_PRICE = "VideoChatPrice",
  PRIVATE_CHAT_PRICE = "PrivateChatPrice",
  VOYEUR_CHAT_PRICE = "VoyeurChatPrice",
  PRIVATE_C2C_CHAT_PRICE = "SingleC2CChatPrice",
  SOFT_CHAT_ENABLED = "SoftChatEnabled",
  VOYEUR_CHAT_ENABLED = "VoyeurChatEnabled",
  VOYEUR_CHAT_PRIVATE_AUDIO = "VoyeurChatPrivateAudio",
  FREE_CHAT_ENABLED = "FreeChatEnabled",
  PARTY_CHAT_ENABLED = "PartyChatEnabled",
}

export enum MSettingValueTypeEnum {
  FLOAT = "float",
  BOOL = "bool",
  ENUM = "enum",
  MULTILANGSTRING = "multiLangString",
}

export class MSetting {
  public name: MSettingNameEnum;
  public type: MSettingValueTypeEnum;
  public isDisabled: boolean;
  public isReadOnly: boolean;
  public isOnlineChangeAllowed: boolean;
}

export class MSettingFloat extends MSetting {
  public type: MSettingValueTypeEnum.FLOAT;
  public value: number;
  public minValue: number;
  public maxValue: number;
  public range: number[] | null;
  public step: number | null;
}

export class MSettingBool extends MSetting {
  public type: MSettingValueTypeEnum.BOOL;
  public value: boolean;
}

export enum RequestStateEnum {
  NOT_SET = "notSet",
  NOT_ALLOWED = "notAllowed",
  REQUESTED = "requested", // this is one writable, if old value == notSet
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export class MSettingFeatureRequest extends MSetting {
  public type: MSettingValueTypeEnum.ENUM;
  public value: RequestStateEnum;
  public requestDate: Date | null;
  public nextPossibleRequestDate: Date | null;
}

export class LanguageText {
  public lang: SupportedLanguage;
  public text: string | null;
}

export class MSettingMultiLangString extends MSetting {
  public type: MSettingValueTypeEnum.MULTILANGSTRING;
  public requiredLangs: SupportedLanguage[];
  public value: LanguageText[];
  public minLength: number | null;
  public maxLength: number | null;
}

/** if one of feilds is missing, so the Settig is not applicable to this account */
export class MSettings {
  public fields: {
    [MSettingNameEnum.CONVERSION_CHAT]?: MSettingBool;
    [MSettingNameEnum.HEATMAP_ENABLED]?: MSettingBool;
    [MSettingNameEnum.HEATMAP_DESCRIPTION]?: MSettingMultiLangString;
    [MSettingNameEnum.LIVE_CHAT_PREVIEW]?: MSettingBool;
    [MSettingNameEnum.LOVENSE_TOY_REQUEST]?: MSettingFeatureRequest;
    [MSettingNameEnum.TICKET_SHOW_REQUEST]?: MSettingFeatureRequest;
    [MSettingNameEnum.MESSENGER_PRICE]?: MSettingFloat;
    [MSettingNameEnum.VIDEO_CHAT_PRICE]?: MSettingFloat;
    [MSettingNameEnum.PRIVATE_CHAT_PRICE]?: MSettingFloat;
    [MSettingNameEnum.VOYEUR_CHAT_PRICE]?: MSettingFloat;
    [MSettingNameEnum.PRIVATE_C2C_CHAT_PRICE]?: MSettingFloat; // MOBILE_VIDEO_CALL
    [MSettingNameEnum.SOFT_CHAT_ENABLED]?: MSettingBool;
    [MSettingNameEnum.VOYEUR_CHAT_ENABLED]?: MSettingBool;
    [MSettingNameEnum.VOYEUR_CHAT_PRIVATE_AUDIO]?: MSettingBool;
    [MSettingNameEnum.FREE_CHAT_ENABLED]?: MSettingBool;
    [MSettingNameEnum.PARTY_CHAT_ENABLED]?: MSettingBool;
  };
}

/**
 *  Get command
 */
export class CMDP_SGETMSETTINGS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETMSETTINGS;
  public params: baseParamsType;
}

/**
 *  Get command response
 */
export class CMDP_SGETMSETTINGS_RESPONSE extends CMDP_SGETMSETTINGS implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    mSettings: JSONString<MSettings>;
  };
}

/**
 *  set command,
 *  the settingValue is JSONString of value for settingName
 */
export class CMDP_SSETMSETTING implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SSETMSETTING;
  public params: baseParamsType & { settingName: MSettingNameEnum; settingValue: string };
}

/**
 *  Set command response
 */
export class CMDP_SSETMSETTING_RESPONSE extends CMDP_SSETMSETTING implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/** NOOP update command */
export class CMDC_MSETTINGS implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_MSETTINGS;
  public params: {
    mSettings: JSONString<MSettings>;
  };
}
