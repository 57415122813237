import {MSettingNameEnum} from 'cmd-control-client-lib';
import _ from 'lodash';
import {put} from 'typed-redux-saga';

import {
	modelSettingsClientOnlyActions,
	TVideoChatSettings,
} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientOnlyActions';
import {modelSettingsClientToServerActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientToServerActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const saveVideoChatSettingsSaga = function* ({
	payload,
}: ReturnType<typeof modelSettingsClientOnlyActions.saveVideoChatSettings>) {
	const priceDict: Record<keyof TVideoChatSettings, MSettingNameEnum> = {
		messengerPrice: MSettingNameEnum.MESSENGER_PRICE,
		videoChatPrice: MSettingNameEnum.VIDEO_CHAT_PRICE,
		privateChatPrice: MSettingNameEnum.PRIVATE_CHAT_PRICE,
		voyeurChatPrice: MSettingNameEnum.VOYEUR_CHAT_PRICE,
		singleC2CChatPrice: MSettingNameEnum.PRIVATE_C2C_CHAT_PRICE,
	};

	try {
		for (const [key, value] of _.toPairs(payload)) {
			const priceType = priceDict[key as keyof TVideoChatSettings];

			yield* put(modelSettingsClientToServerActions.setVideoChatPrice(priceType, value));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveVideoChatSettingsSaga'});
	}
};
