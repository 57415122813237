import {takeEvery} from 'typed-redux-saga';

import {performMessageOptimisticAppendSaga} from '@messenger/core/src/Redux/Messages/Sagas/performMessageOptimisticAppendSaga';
import requestMessagesHistorySaga from '@messenger/core/src/Redux/Messages/Sagas/requestMessagesHistorySaga';
import processRemovedMessageSaga from '@messenger/core/src/Redux/Messages/Sagas/processRemovedMessageSaga';
import {
	messagesClientOnlyActions,
	messagesClientToServerActions,
	messagesServerToClientActions,
} from '@messenger/core/src/Redux/Messages/Actions';
import {notifyOnUploadFailedSaga} from '@messenger/core/src/Redux/Messages/Sagas/notifyOnUploadFailedSaga';
import {stopHighlightingMessageSaga} from '@messenger/core/src/Redux/Messages/Sagas/stopHighlightingMessageSaga';
import updateUploadStatusSaga from '@messenger/core/src/Redux/Messages/Sagas/updateUploadStatusSaga';
import showSoftChatWarningSaga from '@messenger/core/src/Redux/Messages/Sagas/showSoftChatWarningSaga';
import switchActiveChannelToChatSaga from '@messenger/core/src/Redux/Messages/Sagas/switchActiveChannelToChatSaga';
import rejectQueryUserRequestOnChatExitSaga from '@messenger/core/src/Redux/Messages/Sagas/rejectQueryUserRequestOnChatExitSaga';
import {queryServerToClientActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryServerToClientActions';
import {sendMediaSaga} from '@messenger/core/src/Redux/Messages/Sagas/sendMediaSaga';
import processMessagesHistoryReceivedSaga from '@messenger/core/src/Redux/Messages/Sagas/processMessagesHistoryReceivedSaga';
import requestMissingChannelsOnMessageHistoryReceivedSaga from '@messenger/core/src/Redux/Messages/Sagas/requestMissingChannelsOnMessageHistoryReceivedSaga';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';
import deleteMessageSaga from '@messenger/core/src/Redux/Messages/Sagas/deleteMessageSaga';
import deleteMessagesSaga from '@messenger/core/src/Redux/Messages/Sagas/deleteMessagesSaga';
import retrySaveMediaSaga from '@messenger/core/src/Redux/Messages/Sagas/retrySaveMediaSaga';
import submitMessageSaga from '@messenger/core/src/Redux/Messages/Sagas/SubmitMessage/submitMessageSaga';

import {navigateToMessageSaga} from 'src/Redux/Messages/Sagas/navigateToMessageSaga';
import {notifyModelAboutSendingReplyToChannelSaga} from 'src/Redux/Messages/Sagas/notifyModelAboutSendingReplyToChannelSaga';
import closeModalsOnStreamStartedSaga from 'src/Redux/Stream/Sagas/closeModalsOnStreamStartedSaga';
import showWebNotificationSaga from 'src/Redux/Messages/Sagas/showWebNotificationSaga';
import playSoundIfNotSpamOrMuted from 'src/Redux/Messages/Sagas/playSoundIfNotSpamOrMutedSaga';
import afterMessageSentSaga from 'src/Redux/Messages/Sagas/afterMessageSentSaga';
import {processingBulkMessageSentSaga} from 'src/Redux/Messages/Sagas/processingBulkMessageSentSaga';
import {closeProcessingBulkMessageSentSaga} from 'src/Redux/Messages/Sagas/closeProcessingBulkMessageSentSaga';
import processManyMessagesSaga from 'src/Redux/Messages/Sagas/processManyMessagesSaga';
import {markChatMessageSeenSaga} from 'src/Redux/Messages/Sagas/markChatMessageSeenSaga';
import sendCurrentMessageSaga from 'src/Redux/Messages/Sagas/sendCurrentMessageSaga';

const playSoundOn = [
	messagesServerToClientActions.messageReceived.type,
	messagesClientOnlyActions.setMany,
	queryServerToClientActions.queryReceived,
];

const messageSendActions = [
	messagesClientToServerActions.sendMessage,
	messagesClientOnlyActions.sendMessageWithMediaUpload,
];

export default function* messagesSagaWatcher(browser: AbstractUiContainer) {
	yield* takeEvery(messagesServerToClientActions.messageWithMediaReceived.type, updateUploadStatusSaga);
	yield* takeEvery(messagesClientOnlyActions.sendMessageWithMediaUpload, sendMediaSaga);
	yield* takeEvery(messagesClientOnlyActions.sendMessageWithMediaUpload, processingBulkMessageSentSaga);
	yield* takeEvery(messagesClientOnlyActions.markUploaded, closeProcessingBulkMessageSentSaga);
	yield* takeEvery(messagesClientOnlyActions.retrySaveMedia.type, retrySaveMediaSaga);
	yield* takeEvery(playSoundOn, playSoundIfNotSpamOrMuted, browser);
	yield* takeEvery(messagesClientToServerActions.sendMessage, performMessageOptimisticAppendSaga);
	yield* takeEvery(messagesClientOnlyActions.sendMessage.type, submitMessageSaga);
	yield* takeEvery(messagesClientOnlyActions.sendCurrentMessage, sendCurrentMessageSaga);
	yield* takeEvery(messagesClientOnlyActions.deleteMessage.type, deleteMessageSaga);
	yield* takeEvery(messagesClientOnlyActions.deleteMessages, deleteMessagesSaga);
	yield* takeEvery(messageSendActions, afterMessageSentSaga);
	yield* takeEvery(messagesServerToClientActions.messageReceived.type, processRemovedMessageSaga);
	yield* takeEvery(messagesServerToClientActions.messageReceived.type, showWebNotificationSaga, browser);
	yield* takeEvery(messagesServerToClientActions.messageReceived.type, showSoftChatWarningSaga);
	yield* takeEvery(messagesServerToClientActions.messageReceived.type, switchActiveChannelToChatSaga);
	yield* takeEvery(messagesServerToClientActions.messageReceived.type, closeModalsOnStreamStartedSaga);
	yield* takeEvery(messagesServerToClientActions.messageReceived.type, rejectQueryUserRequestOnChatExitSaga);
	yield* takeEvery(messagesServerToClientActions.messagesHistoryReceived.type, processMessagesHistoryReceivedSaga);
	yield* takeEvery(
		messagesServerToClientActions.messagesHistoryReceived,
		requestMissingChannelsOnMessageHistoryReceivedSaga,
	);
	yield* takeEvery(messagesClientOnlyActions.processMany, processManyMessagesSaga);
	yield* takeEvery(messagesClientOnlyActions.markChatMessageSeen, markChatMessageSeenSaga);
	yield* takeEvery(messagesClientOnlyActions.setUploadError.type, notifyOnUploadFailedSaga);
	yield* takeEvery(messagesClientOnlyActions.navigateToMessage, navigateToMessageSaga);
	yield* takeEvery(messagesClientOnlyActions.highlightMessage, stopHighlightingMessageSaga);
	yield* takeEvery(messagesClientOnlyActions.notifyModelAboutSendingReply, notifyModelAboutSendingReplyToChannelSaga);
	yield* takeEvery(messagesClientOnlyActions.requestMessagesHistory, requestMessagesHistorySaga);
}
