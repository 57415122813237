import { channelIdSelectOrFilter, channelIdType, IMayHaveChannelId } from "./channel";
import { EnumBooleanStringified, EnumCurrency } from "../@types";
import { SystemMessageKey } from "./systemmessagekey";
import { IBACKENDPAYLOAD } from "./backend";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "./command/icommand";
import { ChannelMediaLinkState, MayHaveChannelId } from "./media";

export enum MessageFlags {
  "canDeleted",
  "isDeleted",
}

export enum EnumMessageFlagValue {
  NO = "0",
  YES = "1",
}

export enum EnumMessageType {
  MAIL = "mail",
  CHAT = "chat",
  MESSENGER = "msn",
  SYSTEM = "sys",
}

export enum EnumMessageDirection {
  IN = "in",
  OUT = "out",
}

export enum EnumMediaType {
  BITMAP = "Bitmap",
  AUDIO = "Audio",
  VIDEO = "Video",
  TICKET = "Ticket",
  TICKET_SHOW = "TicketShow",
}

export enum EnumMediaState {
  UPLOAD = "uploaded",
  OK = "ok",
  TRANSCODING = "transcode",
  ERROR = "error",
  TRANSCODING_ERROR = "transcodeError",
}

export enum EnumTicketType {
  SingleC2C = "singleC2C",
  TicketShow = "ticketShow",
}

export enum EnumMessageStyle {
  GUEST = "g" /** depretacted */,
  SYSTEM = "s" /** depretacted */,
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
  INFO_HIGHLIGHT = "InfoHighlight",
}

export type Currency = {
  currency?: EnumCurrency;
};

export type Chargeable = {
  isChargeable?: EnumBooleanStringified;
};

/** message params */
export type MessageParams = IMayHaveChannelId & {
  messageId?: string;
  /** your uniq key for message */
  messageKey?: SystemMessageKey | string;
  /** chat id  */
  chatID?: string;
  /** text-message */
  text?: string;
  /** tip, eurocent */
  tip?: string;
  /** gift ids, coma separated */
  gift?: string;
  /** currency, todo: */
  currency?: EnumCurrency;
  /* url */
  link?: string;
  clientData?: string;
  /* no channel out time update on bulk messages */
  isBulk?: EnumBooleanStringified;
  /** reaktion */
  reaction?: string;
};

export type MediaMd5 = {
  // md5 of the media
  mediaMd5?: string;
};

export type MediaInfo = MediaMd5 & {
  /** the media attached */
  mediaType?: EnumMediaType | string;
  /** state of media attached */
  mediaState?: EnumMediaState | string;
  /** datetime of media*/
  mediaCreated?: string;
  linkState?: ChannelMediaLinkState;
  linkDate?: string;
};

export type MediaPrice = Currency & {
  mediaPrice?: string;
};

export type MediaOffer = Chargeable & MediaPrice;

export type ImageFile = MediaInfo & {
  /** Bitmap media*/
  imgSrc?: string;
  imgSrcSet?: string;
  imgHeight?: string;
  imgWidth?: string;
  imgSrcBlurred?: string;
  imgSrcSetBlurred?: string;
};

export type AudioFile = MediaInfo & {
  /** Audio media*/
  audioMp3?: string;
  audioM4a?: string;
  audioOgg?: string;
  audioDuration?: string;
};

export type VideoFile = MediaInfo & {
  /**Video media */
  videoPoster?: string;
  videoPosterBlurred?: string;
  videoFileUrl?: string;
  videoHls?: string;
  videoHeight?: string;
  videoWidth?: string;
  videoDuration?: string;
};

export type Ticket = MediaInfo & {
  ticketDuration?: string;
  ticketType?: EnumTicketType;
};

export type MediaFile = ImageFile | AudioFile | VideoFile | Ticket;

/** id of the message */
export type MessageId = {
  messageId: string;
};

export type RelationId = {
  /** messageId of related message */
  relationId?: string;
  relationText?: string;
  relationMediaType?: string;
};

/** more message params, readonly (set by server, ignored if set by client) */
export type MessageReadOnlyParams = {
  /** the uniq message id */
  messageId?: string;
  /** source of the message IN CMDP_MSG, writable for B2B session */
  msgType?: EnumMessageType;
  /** sent time */
  time?: string;
  /** timeSeen */
  timeSeen?: string;
  /** direction */
  direction?: EnumMessageDirection;
  /** sound name */
  sound?: string;
  /** Will be present for paid message **/
  priceCode?: string;
  /** the message was bought by the opponent*/
  isPaid?: EnumBooleanStringified.TRUE;
  /**
   * coma separated MessageFlags, const flags = cmd.params.flags ? cmd.params.flags.split(',').map((flag: string) =\> flag.trim()) : [];
   * ignored IN CMDP_MSG
   */
  flags?: string | "";
  /*only for preview chats, indicates the preview video is run */
  previewRun?: "1" | "0" | string;

  canDelete?: EnumBooleanStringified.TRUE;
  isDeleted?: EnumBooleanStringified.TRUE;

  /** message style/importance */
  style?: EnumMessageStyle;
} & RelationId &
  MediaFile &
  MediaOffer;

/**
 * Command for transport of MessageType
 */
export class CMDC_CMSG implements ICOMMAND {
  public action = ACTION.CMDC_CMSG;
  public params: MessageParams & MessageReadOnlyParams & MediaFile & MediaOffer & MayHaveChannelId & IKeyMaybeValue;
}

/**
 * send message
 */
export class CMDP_MSG implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_MSG;
  public params: baseParamsType & MessageParams & channelIdSelectOrFilter;
  /** if jwt-session, any json-payload for transport to backend */
  public payload?: unknown;
}

/**
 * Response command for CMDP_MSG
 */
export class CMDP_MSG_RESPONSE extends CMDP_MSG implements IRESPONSE {
  public result: RESULT;
  /** unused */
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
  /** if jwt-session, any json-payload for transport to to client */
  public payload?: unknown;
}

/**
 * delete of message
 * your message will be deleted on your and partner side, partner message will be deleted only on your side
 */
export class CMDP_MSGDELETE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_MSGDELETE;
  public params: baseParamsType & channelIdType & Partial<MessageId>;
}

/**
 * Response for CMDP_MSGDELETE
 */
export class CMDP_MSGDELETE_RESPONSE extends CMDP_MSGDELETE implements IRESPONSE {
  public result: RESULT;
  /** unused */
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
}

/**
 * reaction to message. s. IReaction and CMDP_SINIT.values.reactions
 * CMDC_CMSG will be sent by backend to update the message
 */
export class CMDP_SMSGREACTION implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SMSGREACTION;
  public params: baseParamsType &
    channelIdType &
    MessageId & {
      text?: string;
      /** if the text is empty or not set, the code musst be set to one of CMDP_SINIT.values.reactions */
      code?: string;
      /** reset reaction, if true then text & code are ignored */
      reset?: EnumBooleanStringified;
    };
}

/**
 * Response for CMDP_MSGDELETE
 */
export class CMDP_SMSGREACTION_RESPONSE extends CMDP_SMSGREACTION implements IRESPONSE {
  public result: RESULT;
  /** unused */
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
}

/**
 * Estimate sending message. get count of possible recipients
 */
export class CMDP_SMSGESTIMATE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SMSGESTIMATE;
  public params: baseParamsType &
    channelIdSelectOrFilter & {
      withMedia?: EnumBooleanStringified;
      mediaMd5?: string;
      /* if true, no recipientChannels & skippedChannels is return*/
      countOnly?: EnumBooleanStringified;
    };
}
/**
 * Response for CMDP_SMSGESTIMATE
 */
export class CMDP_SMSGESTIMATE_RESPONSE extends CMDP_SMSGESTIMATE implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    channelCount: string;
    canReceiveCount: string;
    canReceiveChannels: string;
    canPurchaseCount: string;
    canPurchaseChannels?: string;
    hasPurchasedCount: string;
    hasPurchasedChannels?: string;
  };
}

/**
 use https://www.deepl.com/de/docs-api/translate-text/translate-text/
 for sourceLanguage & targetLanguage values
  */
export class CMDP_DEEPL implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_DEEPL;
  public params: baseParamsType & {
    text: string;
    sourceLanguage?: string;
    targetLanguage: string;
    deeplId?: string;
  };
}

export class CMDP_DEEPL_RESPONSE extends CMDP_DEEPL implements IRESPONSE {
  public result: RESULT;

  public commands: ICOMMAND[];

  public values: IKeyMaybeValue & {
    text: string;
    detectedLanguage: string;
  };
}
