import _ from 'lodash';
import {Dictionary} from '@reduxjs/toolkit';

import GiftVM from '@messenger/core/src/Redux/Gifts/GiftVM';
import cachedViewModelFactory from '@messenger/core/src/Utils/cachedViewModelFactory';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {TMessageMediaUploadStatus} from '@messenger/core/src/Redux/Messages/MessageMediaUploadStatus';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import {ChatViewModel} from '@messenger/core/src/Redux/Chats/ViewModel';
import getMessageChatIds from '@messenger/core/src/Utils/Messages/getMessageChatIds';
import {TMessageSentFailed} from '@messenger/core/src/Redux/Messages/slice';

export const getMessageVMCached = cachedViewModelFactory<
	TSswMessageType,
	MessageViewModel,
	[
		giftEntities: Dictionary<GiftVM>,
		guestName: string | undefined,
		guestAvatar: string | undefined,
		isRegularGuest: boolean | undefined,
		isTeamChannel: boolean | undefined,
		isTvAdmin: boolean | undefined,
		uploadStatus: TMessageMediaUploadStatus | undefined,
		failedToSendMessages: string | undefined,
		isSentToAllChats: boolean,
		isSending: boolean,
		isAdminChatMessage: boolean,
	]
>(MessageViewModel);

export const getMessageVMDependencies = (
	message: TSswMessageType,
	giftEntities: Dictionary<GiftVM>,
	mediaUploads: Dictionary<TMessageMediaUploadStatus>,
	failedToSentMessagesData: Dictionary<TMessageSentFailed>,
	channelVMs: Record<string, ChannelVM>,
	sentMessageKeys: Record<string, boolean>,
	messageKeyToIds: Dictionary<string[]>,
	runningChatVMs: ChatViewModel[],
	adminChatVMs: ChatViewModel[],
): Parameters<typeof getMessageVMCached>[1] => {
	const messageChatIds = getMessageChatIds(message);
	const channelVM = message.channelId ? channelVMs[message.channelId] : undefined;
	const chatVM = _.find(runningChatVMs, (runningChatVM) => _.includes(messageChatIds, runningChatVM.chatId));

	return [
		giftEntities,
		getMessageUsername(message, channelVM, chatVM, adminChatVMs),
		getMessageAvatar(channelVM, chatVM),
		_.get(channelVM, 'isRegular', false),
		_.get(channelVM, 'isTeamChannel', false),
		_.get(channelVM, 'isTvAdmin', false),
		message.messageId ? mediaUploads[message.messageId] : undefined,
		message.messageId ? _.get(failedToSentMessagesData, [message.messageId, 'reason'], '') : '',
		message.messageKey && !message.isBulk ? _.size(messageKeyToIds[message.messageKey]) > 1 : false,
		_.get(sentMessageKeys, String(message.messageKey), false),
		!_.chain(adminChatVMs).map('chatId').intersection(messageChatIds).isEmpty().value(),
	];
};

const getMessageUsername = (
	message: TSswMessageType,
	channelVM?: ChannelVM,
	chatVM?: ChatViewModel,
	adminChatVMs?: ChatViewModel[],
) => {
	if (channelVM?.username) {
		return channelVM?.username;
	} else if (message.from) {
		return message.from;
	} else if (message.chatID) {
		if (chatVM?.username) {
			return chatVM?.username;
		}

		const login = _.chain(adminChatVMs).find(['chatId', message.chatID]).get('login').value();

		if (login) {
			return login;
		}
	}

	return undefined;
};

const getMessageAvatar = (channelVM?: ChannelVM, chatVM?: ChatViewModel) => {
	if (channelVM) {
		return channelVM.avatar;
	} else if (chatVM) {
		return chatVM.avatar;
	}

	return undefined;
};
