import _ from 'lodash';
import {EnumMediaType} from 'cmd-control-client-lib';
import {call, put, select} from 'typed-redux-saga';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectTargetGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetGroupIds';
import {selectTargetSystemGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupIds';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {processCheckValidFileSaga} from '@messenger/core/src/Redux/Attachment/Sagas/processCheckValidFileSaga';
import {EnumMediaSource} from '@messenger/core/src/BusinessLogic/Media/EnumMediaSource';
import getMediaType from '@messenger/core/src/Utils/Media/getMediaType';
import {selectMediaPricesVmForTargetChannelIds} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectMediaPricesVmForTargetChannelIds';
import {selectAttachmentById} from '@messenger/core/src/Redux/Attachment/Selectors/selectAttachmentById';
import {mediaLinksClientToServerActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientToServerActions';

export const processSelectAttachmentSaga = function* ({
	payload,
}: ReturnType<typeof attachmentClientOnlyActions.addFileToAttachment>) {
	try {
		const {mediaSource, isBulkMessage, attachmentId, price, file, ...attachmentData} = payload;

		if (!payload.isTicketShow) {
			const {isValid, fileSize} = yield* call(processCheckValidFileSaga, payload);

			if (!isValid) {
				return false;
			}

			if (!_.isUndefined(file) && fileSize && !(file instanceof File)) {
				file.size = fileSize;
			}
		}

		const currentAttachment = yield* select(selectAttachmentById, {attachmentId: attachmentId});
		const targetChannelIds = payload.channelId ? [payload.channelId] : yield* select(selectTargetChannelIds);
		const targetGroupIds = yield* select(selectTargetGroupIds);
		const targetSystemGroupIds = yield* select(selectTargetSystemGroupIds);

		const mediaTypeDefined = payload.mediaType ? payload.mediaType : getMediaType(file);
		const mediaType = payload.isTicketShow ? EnumMediaType.TICKET_SHOW : mediaTypeDefined;
		const prices = yield* select(selectMediaPricesVmForTargetChannelIds);
		const defaultPrice = mediaType ? prices?.getDefault(mediaType) : 0;
		const defaultCurrency = prices?.currency;

		if (payload.isTicketShow) {
			yield* put(
				attachmentClientOnlyActions.attach({
					attachmentId,
					isTicketShow: payload.isTicketShow,
					price: defaultPrice,
					currency: defaultCurrency,
				}),
			);

			return false;
		}

		let mediaMd5 = payload.mediaMd5;
		const isMediaFromStorage = mediaSource === EnumMediaSource.GALLERY || mediaSource === EnumMediaSource.FILE_SYSTEM;

		if (isMediaFromStorage && payload.file) {
			mediaMd5 = yield* call([ServiceFactory.fileProcessing, ServiceFactory.fileProcessing.getMd5], payload.file);
		}

		const isEmptyAttachmentPriceSelected =
			currentAttachment && currentAttachment.isPriceValid && mediaType === currentAttachment.mediaType;

		yield* put(
			attachmentClientOnlyActions.attach({
				...attachmentData,
				file,
				attachmentId,
				mediaMd5,
				mediaType,
				price: isEmptyAttachmentPriceSelected ? currentAttachment.price : defaultPrice,
				currency: defaultCurrency,
				mediaSource: mediaSource,
			}),
		);

		if (
			isMediaFromStorage &&
			(!_.isEmpty(targetChannelIds) || !_.isEmpty(targetGroupIds) || !_.isEmpty(targetSystemGroupIds))
		) {
			if (_.isUndefined(mediaMd5)) {
				throw new Error('missing mediaMd5');
			}

			yield* put(
				mediaLinksClientToServerActions.checkUploadedMedia({
					targetChannelIds,
					mediaMd5,
				}),
			);
		} else if (payload.autoSend) {
			yield* put(messagesClientOnlyActions.sendMessage({}));
		}

		return true;
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processSelectAttachmentSaga'});

		return false;
	}
};

export type TDimensionObject = {
	width: number;
	height: number;
};
