import {takeEvery, takeLeading} from 'typed-redux-saga';

import {processSaveShortcutSaga} from '@messenger/core/src/Redux/Session/Sagas/PermanentData/processSaveShortcutSaga';
import {processDeleteShortcutSaga} from '@messenger/core/src/Redux/Session/Sagas/PermanentData/processDeleteShortcutSaga';
import {
	sessionClientOnlyActions,
	sessionClientToServerActions,
	sessionServerToClientActions,
} from '@messenger/core/src/Redux/Session/Actions';
import processInitResultsSaga from '@messenger/core/src/Redux/Session/Sagas/processInitResultsSaga';
import loadMediaDeviceFromStoreSaga from '@messenger/core/src/Redux/Session/Sagas/loadMediaDeviceFromStoreSaga';
import {notifyOnLoginResultSaga} from '@messenger/core/src/Redux/Session/Sagas/notifyOnLoginResultSaga';
import {sendFeedbackSilentlySaga} from '@messenger/core/src/Redux/Session/Sagas/sendFeedbackSilentlySaga';
import {resetVControlLangSaga} from '@messenger/core/src/Redux/Session/Sagas/resetVControlLangSaga';
import {syncServerLanguageOnClientSaga} from '@messenger/core/src/Redux/Session/Sagas/syncServerLanguageOnClientSaga';
import {processRemoteSessionTermination} from '@messenger/core/src/Redux/Session/Sagas/processRemoteSessionTermination';
import requestPreviewOnSetTpChanges from '@messenger/core/src/Redux/Session/Sagas/requestPreviewOnSetTpChanges';
import {processCurrencySaga} from '@messenger/core/src/Redux/Session/Sagas/processCurrencySaga';
import {processGiftsSaga} from '@messenger/core/src/Redux/Session/Sagas/processGiftsSaga';
import removeNotificationOnKickedByAdminSaga from '@messenger/core/src/Redux/Session/Sagas/removeNotificationOnKickedByAdminSaga';
import saveTeamChannelIdSaga from '@messenger/core/src/Redux/Session/Sagas/saveTeamChannelIdSaga';
import processIsTypingSaga from '@messenger/core/src/Redux/Session/Sagas/processIsTypingSaga';
import {getFeedbackDataFromStorageSaga} from '@messenger/core/src/Redux/Feedback/Sagas/getFeedbackDataFromStorageSaga';
import {toggleKeepSelectedChannelInViewEnabledSaga} from '@messenger/core/src/Redux/Session/Sagas/PermanentData/toggleKeepSelectedChannelInViewEnabledSaga';
import {requestModelOnlineStateSaga} from '@messenger/core/src/Redux/Session/Sagas/requestModelOnlineStateSaga';
import {processUseShortcutSaga} from '@messenger/core/src/Redux/Session/Sagas/PermanentData/processUseShortcutSaga';

import {showCurrentMobileVideoCallNotificationSaga} from 'src/Redux/Session/Sagas/showCurrentMobileVideoCallNotificationSaga';
import {processLoginResultSaga} from 'src/Redux/Session/Sagas/processLoginResultSaga';
import {processSessionUpdateSaga} from 'src/Redux/Session/Sagas/processSessionUpdateSaga';
import {processLogOutSaga} from 'src/Redux/Session/Sagas/processLogOutSaga';
import {keyboardShortcutsSaga} from 'src/Redux/Client/Sagas/Shortcuts/keyboardShortcutsSaga';
import {syncShortcutsListenerSaga} from 'src/Redux/Client/Sagas/Shortcuts/syncShortcutsListenerSaga';
import {showErrorIfLobbyStartFailedSaga} from 'src/Redux/Session/Sagas/showErrorIfLobbyStartFailedSaga';
import {toggleToysPollingSaga} from 'src/Redux/Toy/Sagas/toggleToysPollingSaga';
import loadMaxIntensitySaga from 'src/Redux/Toy/Sagas/loadMaxIntensitySaga';
import {processLoggedOutSaga} from 'src/Redux/Session/Sagas/processLoggedOutSaga';
import {startTicketShowSaga} from 'src/Redux/TicketShow/Sagas/startTicketShowSaga';
import {disableTicketShowSaga} from 'src/Redux/TicketShow/Sagas/disableTicketShowSaga';
import startTicketShowOnOpenLobbyChatSaga from 'src/Redux/Session/Sagas/startTicketShowOnOpenLobbyChatSaga';
import {scheduleAfterTicketShowModalSaga} from 'src/Redux/TicketShow/Sagas/scheduleAfterTicketShowModalSaga';
import {beforeLogOutSaga} from 'src/Redux/Session/Sagas/beforeLogOutSaga';
import {toggleLobbyModeSaga} from 'src/Redux/Session/Sagas/toggleLobbyModeSaga';

const sessionSetTpChangeActions = [
	sessionServerToClientActions.sessionUpdated.type,
	sessionServerToClientActions.loggedIn,
];

function* sessionSagaWatcher() {
	yield* takeEvery(sessionServerToClientActions.initialized, processInitResultsSaga);
	yield* takeEvery(sessionServerToClientActions.initialized, processCurrencySaga);
	yield* takeEvery(sessionServerToClientActions.initialized, processGiftsSaga);
	yield* takeEvery(sessionServerToClientActions.loggedIn, saveTeamChannelIdSaga);
	yield* takeLeading(sessionServerToClientActions.loggedIn, processLoginResultSaga);
	yield* takeLeading(sessionServerToClientActions.loggedIn, loadMediaDeviceFromStoreSaga);
	yield* takeLeading(sessionServerToClientActions.loggedIn, loadMaxIntensitySaga);
	yield* takeEvery(sessionServerToClientActions.loggedIn, notifyOnLoginResultSaga);
	yield* takeEvery(sessionServerToClientActions.loggedIn, keyboardShortcutsSaga);
	yield* takeEvery(sessionServerToClientActions.loggedIn, removeNotificationOnKickedByAdminSaga);
	yield* takeEvery(sessionServerToClientActions.loggedIn, toggleToysPollingSaga);
	yield* takeEvery(sessionServerToClientActions.loggedIn, requestModelOnlineStateSaga);

	// Part of private ticket show functionality. Disable for now.
	//yield* takeEvery(sessionServerToClientActions.loggedIn.type, requestTicketShowsSaga);
	yield* takeEvery(sessionServerToClientActions.loggedIn, getFeedbackDataFromStorageSaga);

	yield* takeEvery(sessionSetTpChangeActions, requestPreviewOnSetTpChanges);
	yield* takeEvery(sessionServerToClientActions.sessionUpdated.type, processSessionUpdateSaga);
	yield* takeEvery(sessionServerToClientActions.sessionUpdated.type, syncServerLanguageOnClientSaga);
	yield* takeEvery(sessionServerToClientActions.sessionUpdated.type, syncShortcutsListenerSaga);
	yield* takeEvery(sessionServerToClientActions.sessionUpdated.type, processIsTypingSaga, true);
	yield* takeEvery(sessionServerToClientActions.sessionUpdated.type, startTicketShowOnOpenLobbyChatSaga);

	yield* takeEvery(sessionClientOnlyActions.logOut, beforeLogOutSaga);
	yield* takeEvery(sessionClientToServerActions.logOut.type, processLogOutSaga);
	yield* takeEvery(sessionServerToClientActions.loggedOut.type, processLoggedOutSaga);

	yield* takeEvery(sessionServerToClientActions.feedbackRequested.type, sendFeedbackSilentlySaga);
	yield* takeEvery(sessionServerToClientActions.sessionCMDPUpdated, resetVControlLangSaga);
	yield* takeEvery(sessionServerToClientActions.sessionRemotelyTerminated, processRemoteSessionTermination);

	yield* takeEvery(sessionServerToClientActions.enableLobbyModeResult.type, showErrorIfLobbyStartFailedSaga);
	yield* takeEvery(sessionServerToClientActions.enableLobbyModeResult.type, scheduleAfterTicketShowModalSaga);
	yield* takeEvery(sessionClientToServerActions.enableLobbyMode.type, startTicketShowSaga);
	yield* takeEvery(sessionClientToServerActions.disableLobbyMode.type, disableTicketShowSaga);
	yield* takeEvery(sessionClientOnlyActions.saveShortcut, processSaveShortcutSaga);
	yield* takeEvery(sessionClientOnlyActions.deleteShortcut, processDeleteShortcutSaga);
	yield* takeEvery(sessionClientOnlyActions.useShortcut, processUseShortcutSaga);
	yield* takeEvery(
		sessionClientOnlyActions.toggleKeepSelectedChannelInViewEnabled,
		toggleKeepSelectedChannelInViewEnabledSaga,
	);
	yield* takeEvery(
		sessionClientOnlyActions.toggleCurrentMobileVideoCallNotification,
		showCurrentMobileVideoCallNotificationSaga,
	);
	yield* takeEvery(sessionClientOnlyActions.toggleLobbyMode, toggleLobbyModeSaga);
}

export default sessionSagaWatcher;
