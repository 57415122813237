import {takeEvery} from 'typed-redux-saga';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {changeFilterEvents} from '@messenger/core/src/BusinessLogic/Channels/changeFilterEvents';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';

import {setCurrentRangeSaga} from 'src/Redux/ChannelsMap/Sagas/setCurrentRangeSaga';
import {refreshCurrentRangeSaga} from 'src/Redux/ChannelsMap/Sagas/refreshCurrentRangeSaga';
import {afterChannelsMapSavedSaga} from 'src/Redux/ChannelsMap/Sagas/afterChannelsMapSavedSaga';
import {requestChannelsMapSaga} from 'src/Redux/ChannelsMap/Sagas/requestChannelsMapSaga';
import {resetChannelsMapSaga} from 'src/Redux/ChannelsMap/Sagas/resetChannelsMapSaga';
import {processChannelSelectionSaga} from 'src/Redux/ChannelsMap/Sagas/processChannelSelectionSaga';
import {processChannelsUpdateSaga} from 'src/Redux/ChannelsMap/Sagas/processChannelsUpdateSaga';
import {startChannelsMapUpdateSaga} from 'src/Redux/ChannelsMap/Sagas/startChannelsMapUpdateSaga';
import {processChatSelectionSaga} from 'src/Redux/ChannelsMap/Sagas/processChatSelectionSaga';

export const resetChannelsMapActions = [...changeFilterEvents, channelsClientOnlyActions.resetFilter.type];

export const channelsMapSagaWatcher = function* () {
	yield* takeEvery(channelsMapClientOnlyActions.setCurrentRange, setCurrentRangeSaga);
	yield* takeEvery(channelsMapClientOnlyActions.refreshCurrentRange, refreshCurrentRangeSaga);
	yield* takeEvery(channelsMapClientOnlyActions.channelsMapSaved, afterChannelsMapSavedSaga);
	yield* takeEvery(channelsMapClientOnlyActions.requestChannelsMap, requestChannelsMapSaga);
	yield* takeEvery(channelsMapClientOnlyActions.requestChannelsMapByWeight, requestChannelsMapSaga);
	yield* takeEvery(channelsMapClientOnlyActions.processChannelSelection, processChannelSelectionSaga);
	yield* takeEvery(channelsMapClientOnlyActions.processChatSelection, processChatSelectionSaga);
	yield* takeEvery(channelsClientOnlyActions.upsertMany, processChannelsUpdateSaga);
	yield* takeEvery(chatsClientOnlyActions.upsertMany, processChannelsUpdateSaga);
	yield* takeEvery(channelsMapClientOnlyActions.startChannelsMapUpdate, startChannelsMapUpdateSaga);
	yield* takeEvery(resetChannelsMapActions, resetChannelsMapSaga);
};
