import {call, cancelled, put, race, select, take} from 'typed-redux-saga';
import _ from 'lodash';
import {Channel, channel, END} from 'redux-saga';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import {selectChosenMediaDevices} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectChosenMediaDevices';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import EnumNotifications from '@messenger/core/src/BusinessLogic/EnumNotifications';
import selectIsAudioRecording from '@messenger/core/src/Redux/Attachment/Selectors/selectIsAudioRecording';
import {EnumAnalyticsCategories, EnumAnalyticsEvents} from '@messenger/core/src/Services/AbstractAnalyticsService';
import {EnumMediaSource} from '@messenger/core/src/BusinessLogic/Media/EnumMediaSource';
import {selectCurrentGuestAttachmentId} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestAttachmentId';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

const AUDIO_RECORDED = 'AUDIO_RECORDED';
const RECORDING_FAILED = 'RECORDING_FAILED';

export const startRecording = function* (selectedDevice: string) {
	try {
		yield* call([ServiceFactory.voiceMessage, ServiceFactory.voiceMessage.start], selectedDevice);
		yield* put(attachmentClientOnlyActions.startRecordingAudio());
	} catch (e) {
		yield* put(attachmentClientOnlyActions.stopRecordingAudio({recordedSuccessfully: false}));
	} finally {
		if (yield* cancelled()) {
			yield* call([ServiceFactory.voiceMessage, ServiceFactory.voiceMessage.cancelRecording]);
		}
	}
};

export const voiceRecordingSaga = function* () {
	try {
		const attachmentId = yield* select(selectCurrentGuestAttachmentId);
		const isAudioRecording = yield* select(selectIsAudioRecording);

		if (isAudioRecording) {
			return;
		}

		const isOnline = yield* select(selectIsOnline);

		if (isOnline) {
			yield* call(
				[ServiceFactory.analyticsService, ServiceFactory.analyticsService.logEvent],
				EnumAnalyticsEvents.RECORDING_AUDIO_WHILE_STREAMING,
				{
					category: EnumAnalyticsCategories.USER_ACTIVITY,
				},
			);

			yield* put(clientClientOnlyActions.showNotification(EnumNotifications.RECORD_AUDIO_IF_ONLINE));

			return;
		}

		yield* call(
			[ServiceFactory.analyticsService, ServiceFactory.analyticsService.logEvent],
			EnumAnalyticsEvents.REGULAR_AUDIO_RECORDING,
			{
				category: EnumAnalyticsCategories.USER_ACTIVITY,
			},
		);

		const {audioinput} = yield* select(selectChosenMediaDevices);

		const {cancelled} = yield* race({
			startedSuccessfully: call(startRecording, audioinput),
			cancelled: take(attachmentClientOnlyActions.stopRecordingAudio.type),
		});

		if (cancelled) {
			return;
		}

		const {payload} = yield* take<ReturnType<typeof attachmentClientOnlyActions.stopRecordingAudio>>(
			attachmentClientOnlyActions.stopRecordingAudio.type,
		);
		const recordedSuccessfully = !!payload?.recordedSuccessfully;
		const autoSend = !!payload?.autoSend;

		const recordingChannel = (yield* call(channel)) as Channel<
			| {type: typeof RECORDING_FAILED}
			| {type: typeof AUDIO_RECORDED; payload: {file: File | ILocalFile; duration: number}}
		>;

		yield* call(
			[ServiceFactory.voiceMessage, ServiceFactory.voiceMessage.stop],
			(file: File | ILocalFile | null, duration: number) => {
				if (_.isNull(file)) {
					recordingChannel.put({type: RECORDING_FAILED});
					recordingChannel.put(END);

					return;
				}

				recordingChannel.put({type: AUDIO_RECORDED, payload: {file, duration}});
				recordingChannel.put(END);
			},
		);

		if (!recordedSuccessfully) {
			return;
		}

		const action = yield* take(recordingChannel);

		if (action.type === RECORDING_FAILED) {
			yield* put(attachmentClientOnlyActions.audioRecordingFailed());

			return;
		}

		const {file, duration} = action.payload;
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		yield* put(
			attachmentClientOnlyActions.addFileToAttachment({
				attachmentId,
				file,
				duration,
				autoSend,
				channelId: guestIdentity?.channelId,
				mediaSource: EnumMediaSource.MICROPHONE,
				isBulkMessage: guestIdentity?.guestType === EnumGuestType.BULK,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'voiceRecordingSaga'});
	}
};
