import {Dispatch} from 'redux';
import {AnyAction} from '@reduxjs/toolkit';
import {EnumBooleanStringified, UploadMediaTus} from 'cmd-control-client-lib';

import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';

export default abstract class AbstractVControlApi {
	protected appendData?: URLSearchParams;
	protected reduxDispatch: Dispatch;

	protected constructor(dispatch: Dispatch) {
		this.appendData = new URLSearchParams();
		this.reduxDispatch = dispatch;
	}

	setAppendData(data: URLSearchParams) {
		this.appendData = data;
	}

	dispatch(action: AnyAction) {
		this.reduxDispatch(action);
	}

	abstract sendMedia(
		uploadUrl: string,
		file: File | ILocalFile,
		params: TSendMediaParams,
		tusConfig?: UploadMediaTus,
	): void;
}

type TMessageWithMediaUpload = ReturnType<
	typeof messagesClientOnlyActions.sendMessageWithMediaUpload
>['payload']['message'];

export type TSendMediaParams = {
	sessionID: string;
	channelId: string;
	text?: string;
	messageKey: string;
	messageId: string;
	mediaType?: string;
	isBulk: EnumBooleanStringified;
	isChargeable: EnumBooleanStringified;
	mediaPrice?: string;
	currency?: string;
} & TMessageWithMediaUpload;

export type TSendMediaTusMeta = TSendMediaParams & {filename: string; filetype: string; uri: string};
