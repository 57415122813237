import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';

export const selectRunningChatIds = createSelector([selectChatVms], (chats) =>
	_.chain(chats)
		.filter((chatVm) => !chatVm.exitCode && chatVm.chatId !== ALL_CHATS)
		.map('chatId')
		.value(),
);
