import { ComaSeparatedValues } from "./sessionstate";
import { chatIdType } from "./chatstate";
import { EnumChannelFlags, EnumBooleanStringified, EnumBooleanStringifiedExtended } from "../@types";
import { IBACKENDPAYLOAD } from "./backend";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IKeyValue, IRESPONSE, RESULT, StringBasedType } from "./command/icommand";
import { CMDC_CMSG, EnumMessageType } from "./message";
import { IB2BUserInfo, IB2BUserInfoVOne } from "./b2b/b2buserinfo";
import { EnumBadge } from "./channelinfo";

/**
 * channelId has Format "usrId.usrKey-partnerId.partnerKey"
 * userPoolId - business id in messenger system, number\{10,0\}
 * usrKey - key of your customer in your sytem, string(36 bytes), "." and "-" are not allowed
 * partnerId - userPoolId of your partner in messenger system, number\{10,0\}
 * partnerKey - key of partner customer, string(36 bytes), "." and "-" are not allowed
 */

/**
 * Type that definitely has `channelId` field
 */
export type channelIdType = {
  channelId: string;
};

/**
 * Type that may or may not have `channelId` field
 * ONLY channel ID, not other fields!
 */
export interface IMayHaveChannelId {
  channelId?: string;
}

export type usrKeyType = {
  /** key of your customer.  obligatory param for b2b session */
  usrKey?: string;
};

export type partnerIdKeyType = {
  /** id of your business partner in messenger system */
  partnerId: string;
  /** key of partners customer */
  partnerKey: string;
};

/** mind one of both, please */
export type channelIdOrChatId = channelIdType | chatIdType;

/**
 * channel
 */
export class ChannelType implements IKeyValue {
  [key: string]: StringBasedType;
  /** channel id */
  public channelId: string;
  /** your business usrId in messenger system */
  public usrId: string; // your master id
  /** the key of your customer */
  public usrKey: string; // your customer id
  /** id of your business partner in messenger system */
  public partnerId: string; // the partner id
  /** key of partner customer */
  public partnerKey: string; // partner customer id
  /** time of last incoming message */
  public lastTimeIn: string;
  /** time of last outgoing message */
  public lastTimeOut: string;
  /** number of unseen messages */
  public unseen: string;
  /** update spam serial */
  public serial: string;
  /** @see EnumChannelFlags */
  public flags?: ComaSeparatedValues<EnumChannelFlags> | EnumChannelFlags;
  /** the weight of the channel*/
  public weight?: string;
  /*time for next possible outgoing message */
  public nextTimeOut?: string;
}

/**
 * the command for delivery channel info
 */
export class CMDC_CHANNEL implements ICOMMAND {
  public action = ACTION.CMDC_CHANNEL;
  public params: ChannelType;
}

/**
 * create new channel. the channel must be created before send of message
 */
export class CMDP_NEWCHANNEL implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_NEWCHANNEL;
  public params: baseParamsType & usrKeyType & partnerIdKeyType & (IB2BUserInfo | IB2BUserInfoVOne);
}

/**
 * response for CMDP_NEWCHANNEL
 */
export class CMDP_NEWCHANNEL_RESPONSE extends CMDP_NEWCHANNEL implements IRESPONSE {
  public result: RESULT;
  /** the created channel */
  public commands: CMDC_CHANNEL[];
  /** deprecated. the created channel, values is ChannelType */
  public values: ChannelType;
}

/**
 * Get the ChannelInfo. this command works with channelId and will be reflected to backend.
 */
export class CMDP_GETCHANNEL implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_GETCHANNEL;
  public params: baseParamsType & channelIdType;
  public payload?: unknown;
}

/**
 * response for CMDP_GETCHANNEL
 */
export class CMDP_GETCHANNEL_RESPONSE extends CMDP_GETCHANNEL implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  /** the channel info */
  public commands: CMDC_CHANNEL[];
  /** unused, empty */
  public values: IKeyMaybeValue;
  /** the backend info to the channel */
  public payload?: unknown;
}

/**
 * 8320 only.
 */
export class CMDP_SGETCHANNEL extends CMDP_GETCHANNEL {
  public action: ACTION = ACTION.CMDP_SGETCHANNEL;
}

/**
 * 8320 only
 */
export class CMDP_SGETCHANNEL_RESPONSE extends CMDP_SGETCHANNEL {
  public action: ACTION = ACTION.CMDP_SGETCHANNEL;
}

export enum EnumSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum EnumChannelFilterValues {
  ANY = "any",
  ONLY = "only",
  EXCLUDE = "exclude",
}

export enum EnumProductId {
  Default = "1",
  PAGES = "2",
  PARTNER = "3",
  TV = "4",
}

/** channel selector type */
export type channelIdSelector = {
  channelId?: ComaSeparatedValues<string> | string;
  selectActive?: EnumBooleanStringified;
  selectRegularCustomer?: EnumBooleanStringified;
  selectAdvertised?: EnumBooleanStringified;
  selectPinned?: EnumBooleanStringified;
  selectGroupId?: ComaSeparatedValues<string> | string;
  selectBadges?: ComaSeparatedValues<EnumBadge> | string;
  /** deprecated */
  selectVip?: EnumBooleanStringified;
  groupId?: ComaSeparatedValues<string> | string;
};

export type channelIdFilter = {
  /** search for contact-name, default empty, any lenght */
  filterContactName?: string;
  /** filter channels without incoming messages, default any */
  filterEmptyIn?: EnumChannelFilterValues;
  /** filter channels without outgoing messages, default any */
  filterEmptyOut?: EnumChannelFilterValues;
  /** filter archived channels, default any */
  filterArchived?: EnumChannelFilterValues;
  /** 8320 only , default any */
  filterAdvertised?: EnumChannelFilterValues;
  /** filter by favorite flag, default any */
  filterRegularCustomer?: EnumChannelFilterValues;
  /** filter by unseen, default any */
  filterUnseen?: EnumChannelFilterValues;
  /** filter by unanswered, default any */
  filterUnAnswered?: EnumChannelFilterValues;
  /** filter by pinned, default any */
  filterPinned?: EnumChannelFilterValues;
  /** filter by muted, default any */
  filterMuted?: EnumChannelFilterValues;
  /** filter by VIP status, default any */
  filterVIP?: EnumChannelFilterValues;
  /** has a video chat */
  filterVideoChat?: EnumChannelFilterValues;
  /** cant sent message*/
  filterBlockedOutbound?: EnumChannelFilterValues;
  /** filter by product id, default empty*/
  filterProductId?: EnumProductId;
  /** filter by banned */
  filterBanned?: EnumChannelFilterValues;
  /** filter has ticket */
  filterTicket?: EnumChannelFilterValues;
  /** media filter */
  filterCanReceiveImage?: EnumChannelFilterValues;
  filterCanReceiveAudio?: EnumChannelFilterValues;
  filterCanReceiveVideo?: EnumChannelFilterValues;
  filterCanReceiveTicket?: EnumChannelFilterValues;
  /** media purchasing filter */
  filterCanPurchaseMedia?: EnumChannelFilterValues;
  /** filter by group id, coma separated */
  filterGroupId?: string;
  /** filter by badges */
  selectBadges?: ComaSeparatedValues<EnumBadge> | string;
  /** filterActive */
  filterActive?: EnumChannelFilterValues;
  /** filter channels to exclude */
  filterExcludeChannelId?: ComaSeparatedValues<string> | string;
  /** filter by weight */
  filterMinWeight?: string;
  filterMaxWeight?: string;
};

export type channelIdSelectOrFilter = channelIdSelector | channelIdFilter;

export type getChannelsParamsType = {
  /** from serial, default = 0 */
  serial?: string;
  /** query offset */
  skip?: string;
  /** limit, default is 1000 */
  limit?: string;
  /* sort by serial default DESC, deprecated, ignored by server*/
  sort?: EnumSortOrder;
  /** get CMDC_CONTACTNOTE in commands, default 0, deprecated */
  contactNote?: EnumBooleanStringifiedExtended;
  /** get CMDC_CONTACTINFO in commands, default 0, allowed for b2b session only */
  contactInfo?: EnumBooleanStringifiedExtended;
  /** get CMDC_CHANNELINFO in commands, default 0 */
  channelInfo?: EnumBooleanStringifiedExtended;
  /** get CMDC_ONLINESTATE in commands */
  onlineState?: EnumBooleanStringifiedExtended;
  /** get CMDC_CHANNELGROUP in commands */
  groupInfo?: EnumBooleanStringifiedExtended;
  /** search for contact-name, default empty */
  /**deprecated */
  contactSearch?: string;
};

/**
 * Query for ChannelList
 */
export class CMDP_GETCHANNELS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETCHANNELS;
  public params: baseParamsType &
    getChannelsParamsType &
    channelIdSelectOrFilter & {
      countOnly?: EnumBooleanStringified;
    };
}

/**
 * Response for CMDP_GETCHANNELS
 */
export class CMDP_GETCHANNELS_RESPONSE extends CMDP_GETCHANNELS implements IRESPONSE {
  public result: RESULT;
  /** channels & channel infos, if countOnly omited or "false" */
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue & {
    channelCount?: string;
    channelTotal?: string;
  };
}

/** synonym, 8320 only */
export class CMDP_SGETCHANNELS extends CMDP_GETCHANNELS {
  public action: ACTION = ACTION.CMDP_SGETCHANNELS;
}

/** synonym, 8320 only */
export class CMDP_SGETCHANNELS_RESPONSE extends CMDP_GETCHANNELS_RESPONSE {
  public action: ACTION = ACTION.CMDP_SGETCHANNELS;
}

/** searchAfter must be used together */
export type IMayHaveSearchAfter = {
  /** filter for datetime, value like CMDC_CMSG.param.timeSent (unix time stamp in ms)*/
  searchAfterTime?: string;
  searchAfterId?: string;
};

export type getHistoryParamsType = IMayHaveChannelId &
  IMayHaveSearchAfter & {
    /** filter for msgType. coma separated */
    filterMessageType?: EnumMessageType | string;
    /** filter for isDeleted*/
    filterDeleted?: EnumChannelFilterValues;
    /** number of messages, max & default is 100 */
    limit?: string;
    /** sort by sent, default is DESC */
    sort?: EnumSortOrder;
    /** deprecated */
    keys?: EnumBooleanStringifiedExtended;
  };

/**
 * Get messages history for channel
 * Multichannel command
 */
export class CMDP_GETHISTORY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETHISTORY;
  public params: baseParamsType &
    getHistoryParamsType & {
      getUnseenButDeleted?: EnumBooleanStringified;
    };
}

/**
 * Response for CMDP_GETHISTORY
 */
export class CMDP_GETHISTORY_RESPONSE extends CMDP_GETHISTORY implements IRESPONSE {
  public result: RESULT;
  /** found messages */
  public commands: CMDC_CMSG[];
  /** unused */
  public values: IKeyMaybeValue;
}

export enum EnumLimitType {
  TOTAL = "total",
  separately = "separately",
}

/**
 * Get messages history for channel
 * Multichannel command
 */
export class CMDP_SGETMESSAGEHISTORY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETMESSAGEHISTORY;
  public params: baseParamsType &
    getHistoryParamsType & {
      /** chat id list, comaseparated */
      chatIDs?: string;
      /** limit type, default total */
      limitType?: EnumLimitType;
    };
}

/**
 * Response for CMDP_GETHISTORY
 */
export class CMDP_SGETMESSAGEHISTORY_RESPONSE extends CMDP_SGETMESSAGEHISTORY implements IRESPONSE {
  public result: RESULT;
  /** found messages */
  public commands: CMDC_CMSG[];
  /** unused */
  public values: IKeyMaybeValue;
}

export type channelSeenParamsType = channelIdType & {
  /** last seen messageId, by default all messages marked as seen */
  messageId?: string;
};

/**
 * Mark messages as seen
 */
export class CMDP_CHANNELSEEN implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_CHANNELSEEN;
  public params: baseParamsType & channelSeenParamsType;
}

/** alias, 8320 only */
export class CMDP_SCHANNELSEEN extends CMDP_CHANNELSEEN {
  public action: ACTION = ACTION.CMDP_SCHANNELSEEN;
}

/** alias, 8320 only */
export class CMDP_CHANNELSEEN_RESPONSE extends CMDP_SCHANNELSEEN implements IRESPONSE {
  public result: RESULT;
  /** unused */
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
}

/** alias, 8320 only */
export class CMDP_SCHANNELSEEN_RESPONSE extends CMDP_CHANNELSEEN_RESPONSE {
  public action: ACTION = ACTION.CMDP_SCHANNELSEEN;
}

export enum EnumUpdateChannelPropertyName {
  ARCHIVED = "archived",
  REGULAR_CUSTOMER = "regularCustomer",
  UNANSWERED = "unanswered",
  PINNED = "pinned",
  MUTED = "muted",
  BANNED = "banned",
  BLOCKED_INBOUND = "blockedInbound",
  BLOCKED_OUTBOUND = "blockedOutbound",
}

export class updateChannelParamsType implements IKeyMaybeValue {
  [key: string]: string;
  /** flag name */
  public propertyName: EnumUpdateChannelPropertyName;
  /** flag value */
  public propertyValue: EnumBooleanStringified;
}

/**
 * Set Flag on Channel
 * @deprecated use CMDP_SETCHANNELINFO
 */
export class CMDP_UPDATECHANNEL implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_UPDATECHANNEL;
  public params: baseParamsType & updateChannelParamsType;
  public payload?: unknown;
}

/**
 * Response for CMDP_UPDATECHANNEL
 */
export class CMDP_UPDATECHANNEL_RESPONSE extends CMDP_UPDATECHANNEL implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  /** unused */
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
  public payload?: unknown;
}

/**
 * channel summary info
 */
export type channelSummaryInfo = {
  [key: string]: string;
  /** your business id */
  usrId: string;
  /** usrKey of your user */
  usrKey: string;
  /** number of channel */
  channels: string;
  /** number of unseen messages */
  unseen: string;
  /** number of unseen messages */
  unseenWithoutArchived: string;
  /** number of unseen channels */
  unseenChannels: string;
  /** number of unseen channels without archived*/
  unseenChannelsWithoutArchived: string;
  /** number of unanswered channels */
  unanswered: string;
  /** number of unanswered channels */
  unansweredWithoutArchived: string;
  /** last time of incoming message  */
  lastTimeIn: string;
  /** last time of outgoing message */
  lastTimeOut: string;
  /** number of archived channels */
  archived: string;
  /** number of advertised channels */
  advertised: string;
  /** number of regular channels */
  regular: string;
  /** number of pinned channels */
  pinned: string;
  /** number of muted channels */
  muted: string;
  /** number of banned channels */
  banned: string;
  /** number of vip channels */
  vip: string;
  /** number of online channels */
  online: string;
  /** number of active channels */
  active: string;
};

/**
 * channel summary
 */
export class CMDC_CHANNELSUMMARY implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_CHANNELSSUMMARY;
  public params: channelSummaryInfo;
}

/**
 * Query the summary info about all channels
 */
export class CMDP_CHANNELSSUMMARY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_CHANNELSSUMMARY;
  public params: baseParamsType & usrKeyType;
}

/**
 * Response for CMDP_CHANNELSSUMMARY
 */
export class CMDP_CHANNELSSUMMARY_RESPONSE extends CMDP_CHANNELSSUMMARY implements IRESPONSE {
  public result: RESULT;
  /** unused */
  public commands: ICOMMAND[];
  /** the summary info */
  public values: channelSummaryInfo;
}
