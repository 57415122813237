import _ from 'lodash';
import {call, delay, race, select, take, put} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';
import {GroupedVirtuosoHandle} from 'react-virtuoso';
import {EnumMediaState} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {selectMessagesSentChannelCount} from '@messenger/core/src/Redux/Messages/Selectors/selectMessagesSentChannelCount';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {
	messagesClientOnlyActions,
	messagesClientToServerActions,
	messagesServerToClientActions,
} from '@messenger/core/src/Redux/Messages/Actions';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectIsKeepSelectedChannelInViewEnabled} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectIsKeepSelectedChannelInViewEnabled';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {ObjectStoreService} from '@messenger/core/src/Services/ObjectStore/ObjectStoreService';
import {getMessageHistoryRefId} from '@messenger/uikit/src/MessagesHistory/MessagesHistory';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

const afterMessageSentSaga = function* ({
	payload,
}:
	| ReturnType<typeof messagesClientToServerActions.sendMessage>
	| ReturnType<typeof messagesClientOnlyActions.sendMessageWithMediaUpload>) {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (guestIdentity?.guestType !== EnumGuestType.BULK) {
			const objectStoreKey =
				guestIdentity?.guestType === ALL_CHATS ? ALL_CHATS : guestIdentity?.channelId || guestIdentity?.chatId;

			if (objectStoreKey) {
				const virtuosoHandle = ObjectStoreService.get<GroupedVirtuosoHandle>(getMessageHistoryRefId(objectStoreKey));

				if (ServiceFactory.uiContainer.isMobileIos()) {
					yield* delay(1000);
				}

				virtuosoHandle?.scrollToIndex({index: 'LAST'});
			}

			const isKeepSelectedChannelInViewEnabled = yield* select(selectIsKeepSelectedChannelInViewEnabled);

			if (guestIdentity?.guestType === EnumGuestType.CHANNEL && isKeepSelectedChannelInViewEnabled) {
				const [, action] = yield* race([delay(2000), take(channelsMapClientOnlyActions.channelsMapSaved)]);

				if (action) {
					yield* put(channelsMapClientOnlyActions.processChannelSelection({channelId: guestIdentity.channelId}));
				}
			}

			return;
		}

		const {
			notifications,
			i18n: {t},
		} = ServiceFactory;

		let sentChannelCount = _.parseInt((yield* select(selectMessagesSentChannelCount)) || '0', 10);

		const {goToChannels, goToChatMessages} = yield* getNavigationContext();

		if (isMobile && ServiceFactory.env.isAppMobileEnabled()) {
			yield* call(goToChannels);
		} else {
			yield* call(goToChatMessages, ALL_CHATS);
		}

		if (sentChannelCount === 0) {
			const {meta} = yield* take(messagesServerToClientActions.messageSendResult);

			sentChannelCount = _.parseInt(meta.values?.channelCount || '0', 10);
		}

		if (sentChannelCount > 0 && _.get(payload, 'message.mediaState') !== EnumMediaState.UPLOAD) {
			yield* call([notifications, notifications.enqueue], {
				variant: EnumAbstractNotificationVariant.SUCCESS,
				key: EnumSnackbarNotificationKeys.BULK_MESSAGE_SENT,
				text: t('bulkMessage:sent', {total: sentChannelCount}),
				duration: 5000,
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'afterMessageSentSaga'});
	}
};

export default afterMessageSentSaga;
