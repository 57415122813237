import {generatePath} from 'react-router';

import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import EnumSettingsTabs from '@messenger/core/src/BusinessLogic/EnumSettingsTabs';

import {EnumRouteSlugs} from 'src/Routes/EnumRouteSlugs';

export const Routes = {
	home: '/',
	chats: `/${EnumRouteSlugs.CHATS}`,
	chatsAll: `/${EnumRouteSlugs.CHATS_ALL}`,
	chat: (chatId: string) => generatePath(`/${EnumRouteSlugs.CHAT}`, {chatId}),
	channels: `/${EnumRouteSlugs.CHANNELS}`,
	channel: (channelId: string) => generatePath(`/${EnumRouteSlugs.CHANNEL}`, {channelId}),
	channelMessage: (channelId: string) => generatePath(`/${EnumRouteSlugs.CHANNEL_MESSAGE}`, {channelId}),
	channelInfo: (channelId: string) => generatePath(`/${EnumRouteSlugs.CHANNEL_INFO}`, {channelId}),

	liveChat: `/${EnumRouteSlugs.LIVECHAT}`,
	settings: `/${EnumRouteSlugs.SETTINGS}`,
	bulkMessage: `/${EnumRouteSlugs.BULK_MESSAGE}`,
	liveSessions: `/${EnumRouteSlugs.LIVE_SESSIONS}`,
	liveSession: (liveSessionId: string) => generatePath(`/${EnumRouteSlugs.LIVE_SESSION}`, {liveSessionId}),

	modal: {
		intro: `${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.INTRO}`,
		introChannel: `/${EnumRouteSlugs.CHANNELS}/${EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE}/${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.INTRO}`,
		sipCall: `${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.SIP_CALL}`,
		settingsTab: (tab: EnumSettingsTabs) =>
			generatePath(`${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.SETTINGS}/${EnumRouteSlugs.PARAM_TAB}`, {tab}),
		offerDiscount: (chatId: string) =>
			generatePath(`${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.OFFER_DISCOUNT}`, {chatId}),
		moveToSpam: (channelId: string) =>
			generatePath(`${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.MOVE_TO_SPAM}`, {channelId}),
		ban: (channelId: string) => generatePath(`${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.BAN}`, {channelId}),
		kick: (chatId: string) => generatePath(`${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.KICK}`, {chatId}),
		report: (channelId: string) => generatePath(`${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.REPORT}`, {channelId}),
		contentCatalog: (channelId?: string) =>
			generatePath(`${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.CONTENT_CATALOG}/${EnumRouteSlugs.PARAM_CHANNEL_ID}`, {
				channelId: channelId || '',
			}),
		voiceRecorder: `${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.VOICE_RECORDER}`,
		sendProcessing: `${EnumRouteSlugs.MODAL}/${EnumRouteSlugs.SEND_PROCESSING}`,
	},

	isModal: (path?: string) => path && path.indexOf(`${EnumRouteSlugs.MODAL}/`) !== -1,
	isModalSlug: (path?: string) => path && path.indexOf(`${EnumRouteSlugs.MODAL}/`) === 0,
};
