import {EnumMediaState, EnumMediaType, UploadedMediaDirection} from 'cmd-control-client-lib';
import _ from 'lodash';
import moment from 'moment';

import {TMessageImageSource} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import {oneHourInSeconds} from '@messenger/core/src/BusinessLogic/TimeIntervals';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TMediaPreviewInfo} from '@messenger/core/src/Types/media';

import {TMedia} from './mediaEntityAdapter';

export const MEDIA_FIELDS = [
	'mediaMd5',
	'mediaType',
	'mediaState',
	'mediaCreated',
	'direction',

	'imgSrc',
	'imgSrcSet',
	'imgHeight',
	'imgWidth',
	'imgSrcBlurred',
	'imgSrcSetBlurred',

	'audioMp3',
	'audioM4a',
	'audioOgg',
	'audioDuration',

	'videoPoster',
	'videoPosterBlurred',
	'videoFileUrl',
	'videoHls',
	'videoHeight',
	'videoWidth',
	'videoDuration',
];

export class MediaVm {
	mediaMd5: string;
	mediaType: EnumMediaType;
	mediaState: EnumMediaState;
	direction: UploadedMediaDirection;

	imgSrcSet?: string;
	imgSrc?: string;
	imgHeight = 0;
	imgWidth = 0;
	imageSource: string = '';
	imageSourceSet: TMessageImageSource[] = [];

	videoHeight = 0;
	videoWidth = 0;
	videoHls?: string;
	videoFileUrl?: string;
	videoUrl?: string;
	isVerticalVideo: boolean = false;

	audioM4a?: string;
	audioMp3?: string;
	audioOgg?: string;
	audioSourcesList: string[] = [];

	duration?: number;
	formattedDuration?: string;
	poster?: string;

	isTicketMediaType = false;
	isOkMediaState: boolean;
	hasTranscodingError: boolean;
	readonly media: TMedia;
	readonly mediaPreviewInfo: TMediaPreviewInfo;

	constructor({mediaType, mediaMd5, mediaState, mediaCreated, direction, ...media}: TMedia) {
		let duration: number;

		this.mediaMd5 = String(mediaMd5);
		this.mediaType = mediaType as EnumMediaType;
		this.mediaState = (mediaState as EnumMediaState) || EnumMediaState.OK;
		this.direction = direction || UploadedMediaDirection.OUT;

		switch (mediaType) {
			case EnumMediaType.BITMAP:
				this.mediaType = mediaType;
				this.imgSrcSet = _.get(media, 'imgSrcSet');
				this.imgSrc = _.get(media, 'imgSrc');
				this.imgHeight = _.chain(media).get('imgHeight', '0').parseInt(10).value();
				this.imgWidth = _.chain(media).get('imgWidth', '0').parseInt(10).value();
				this.imageSource = this.imgSrc || _.get(media, 'imgPixelated');

				this.poster = this.imgSrc;
				this.imageSourceSet = this.getImageSourceSet();

				break;

			case EnumMediaType.VIDEO:
				this.videoHeight = _.chain(media).get('videoHeight', '0').parseInt(10).value();
				this.videoWidth = _.chain(media).get('videoWidth', '0').parseInt(10).value();
				this.videoHls = _.get(media, 'videoHls');
				this.videoFileUrl = _.get(media, 'videoFileUrl');
				this.videoUrl = this.videoHls ?? this.videoFileUrl;
				this.poster = _.get(media, 'videoPoster');

				duration = _.chain(media).get('videoDuration').parseInt(10).value();
				this.duration = isNaN(duration) ? undefined : duration;

				if (this.videoHeight && this.videoWidth) {
					this.isVerticalVideo = this.videoHeight > this.videoWidth;
				}

				break;

			case EnumMediaType.AUDIO:
				this.audioM4a = _.get(media, 'audioM4a');
				this.audioMp3 = _.get(media, 'audioMp3');
				this.audioOgg = _.get(media, 'audioOgg');

				duration = _.chain(media).get('audioDuration').parseInt(10).value();
				this.duration = isNaN(duration) ? undefined : duration;

				this.audioSourcesList = _.compact([this.audioM4a, this.audioMp3, this.audioOgg]);

				break;
		}

		this.formattedDuration =
			_.includes([EnumMediaType.VIDEO, EnumMediaType.AUDIO], mediaType) && !_.isUndefined(this.duration)
				? moment
						.utc(moment.duration(this.duration, 'seconds').as('milliseconds'))
						.format(
							this.duration < oneHourInSeconds
								? ServiceFactory.i18n.t('momentFormat:durationFormat.minutes')
								: ServiceFactory.i18n.t('momentFormat:durationFormat.hours'),
						)
				: undefined;

		this.isOkMediaState = this.mediaState === EnumMediaState.OK;
		this.hasTranscodingError = this.mediaState === EnumMediaState.TRANSCODING_ERROR;
		this.isTicketMediaType = EnumMediaType.TICKET === this.mediaType;

		this.mediaPreviewInfo = this.getMediaPreviewInfo();

		this.media = {
			..._.pick(media, MEDIA_FIELDS),

			mediaCreated,
			direction,
			mediaMd5,
			mediaState,
			mediaType,
		};
	}

	private getImageSourceSet(): TMessageImageSource[] {
		if (!_.isEmpty(this.imgSrcSet)) {
			const aspectRatio = !_.isUndefined(this.imgHeight) && this.imgWidth ? this.imgHeight / this.imgWidth : 1;

			return _.chain(this.imgSrcSet)
				.split(',')
				.map((part: string) => {
					const [uri, width] = _.split(part, ' ');

					return {
						uri,
						width: _.parseInt(width, 10),
						height: _.parseInt(width, 10) * aspectRatio,
					};
				})
				.sortBy('width')
				.value();
		}

		return [
			{
				uri: this.imageSource,
				width: this.imgWidth,
				height: this.imgHeight,
			},
		];
	}

	getAppropriateImage(width: number) {
		return _.find(this.imageSourceSet, (image) => image.width >= width) || _.last(this.imageSourceSet);
	}

	private getMediaPreviewInfo(): TMediaPreviewInfo {
		switch (this.mediaType) {
			case EnumMediaType.VIDEO:
				return {
					type: this.mediaType,
					src: this.videoUrl,
					poster: this.poster,
					isTranscoded: !(
						_.startsWith(this?.videoUrl, 'blob') ||
						this.hasTranscodingError ||
						this.mediaState === EnumMediaState.TRANSCODING
					),
				};

			case EnumMediaType.AUDIO:
				return {
					type: this.mediaType,
					src: _.head(this?.audioSourcesList) || '',
					duration: this.formattedDuration,
				};

			case EnumMediaType.TICKET:
				return {
					type: this.mediaType,
				};

			case EnumMediaType.BITMAP:
			default:
				return {
					type: EnumMediaType.BITMAP,
					src: this.imageSource,
					srcSet: this.imgSrcSet,
				};
		}
	}
}
