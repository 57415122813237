import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import selectCurrentMessageInput from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInput';
import {EDITOR_MAX_LENGTH} from '@messenger/core/src/BusinessLogic/Constants/Constants';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';

export const insertToCurrentCaretPositionSaga = function* ({
	payload,
}: ReturnType<typeof messageInputClientOnlyActions.insertToCurrentCaretPosition>) {
	try {
		const messageInput = yield* select(selectCurrentMessageInput);

		if (_.size(messageInput.value) >= EDITOR_MAX_LENGTH) {
			return;
		}

		const caretPosition = messageInput.caretPosition || 0;
		const currentValue = messageInput.value || '';

		const value =
			currentValue.substring(0, caretPosition) + payload + currentValue.substring(caretPosition, currentValue.length);

		yield* put(
			messageInputClientOnlyActions.upsertOne({
				id: messageInput.id,
				value,
				caretPosition: caretPosition + payload.length,
			}),
		);
		yield* put(channelsClientOnlyActions.notifyIsTyping());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'insertToCurrentCaretPositionSaga'});
	}
};
