import {put, select} from 'typed-redux-saga';
import {EnumMediaType} from 'cmd-control-client-lib';

import {selectCurrentAttachmentVm} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachmentVm';
import {selectMediaPricesVmForTargetChannelIds} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectMediaPricesVmForTargetChannelIds';
import {selectCurrentGuestAttachmentId} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestAttachmentId';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TAttachment} from '@messenger/core/src/Redux/Attachment/Model';

export function* processAddEmptyAudioAttachmentSaga() {
	try {
		const currentAttachment = yield* select(selectCurrentAttachmentVm);
		const attachmentId = yield* select(selectCurrentGuestAttachmentId);

		if (!currentAttachment || attachmentId !== currentAttachment.attachmentId) {
			const prices = yield* select(selectMediaPricesVmForTargetChannelIds);
			const defaultPrice = prices?.getDefault(EnumMediaType.AUDIO);
			const defaultCurrency = prices?.currency;
			const attachment: TAttachment = {
				attachmentId,
				mediaType: EnumMediaType.AUDIO,
				price: defaultPrice,
				currency: defaultCurrency,
			};

			yield* put(attachmentClientOnlyActions.attach(attachment));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processAddEmptyAudioAttachmentSaga'});
	}
}

export default processAddEmptyAudioAttachmentSaga;
