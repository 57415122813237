import {fork, takeEvery, takeLeading} from 'typed-redux-saga';

import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import changeLanguageOnClientSaga from '@messenger/core/src/Redux/Client/Sagas/changeLanguageOnClientSaga';
import changeLanguageOnServerSaga from '@messenger/core/src/Redux/Client/Sagas/changeLanguageOnServerSaga';
import {processBackendConnectionStatusUpdateSaga} from '@messenger/core/src/Redux/Client/Sagas/processBackendConnectionStatusUpdateSaga';
import {showCopiedToClipboardNotification} from '@messenger/core/src/Redux/Client/Sagas/showCopiedToClipboardNotification';
import storeFontSizeSaga from '@messenger/core/src/Redux/Client/Sagas/storeFontSizeSaga';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';

import {gatherSupportedCodecsSaga} from 'src/Redux/Client/Sagas/gatherSupportedCodecsSaga';
import {navigateChatsAndChannelsSaga} from 'src/Redux/Client/Sagas/navigateChatsAndChannelsSaga';
import notificationSaga from 'src/Redux/Client/Sagas/notificationSaga';
import {processGlobalErrorSaga} from 'src/Redux/Client/Sagas/processGlobalErrorSaga';
import {processNetworkStatusUpdateSaga} from 'src/Redux/Client/Sagas/processNetworkStatusUpdateSaga';
import {processStartApplicationSaga} from 'src/Redux/Client/Sagas/processStartApplicationSaga';
import {resetGuestFiltersSaga} from 'src/Redux/Client/Sagas/resetGuestFiltersSaga';
import {restartShortcutsProcessingSaga} from 'src/Redux/Client/Sagas/Shortcuts/restartShortcutsProcessingSaga';
import {stopShortcutsOnModalOpenedSaga} from 'src/Redux/Client/Sagas/Shortcuts/stopShortcutsOnModalOpenedSaga';
import {playSoundSaga} from 'src/Redux/Client/Sagas/Sounds/playSoundSaga';
import {toggleGuestTypeFilterSaga} from 'src/Redux/Client/Sagas/toggleGuestTypeFilterSaga';
import {toggleUserInfoAreaSaga} from 'src/Redux/Client/Sagas/toggleUserInfoAreaSaga';
import windowEventsWatcher from 'src/Redux/Client/Window/windowEventsWatcher';

const stopShortcutsOn = [clientClientOnlyActions.disableShortcuts];
const startShortcutsOn = [clientClientOnlyActions.enableShortcuts];

function* clientSagaWatcher(browser: AbstractUiContainer) {
	yield* fork(windowEventsWatcher, browser);
	yield* takeEvery(clientClientOnlyActions.copiedToClipboard.type, showCopiedToClipboardNotification);
	yield* takeEvery(clientClientOnlyActions.resetGuestFilters, resetGuestFiltersSaga);
	yield* takeEvery(clientClientOnlyActions.toggleGuestTypeFilter, toggleGuestTypeFilterSaga);
	yield* takeEvery(clientClientOnlyActions.setClientReady.type, processStartApplicationSaga, browser);
	yield* takeEvery(clientClientOnlyActions.setClientReady.type, gatherSupportedCodecsSaga, browser);
	yield* takeEvery(clientClientOnlyActions.changeLanguage.type, changeLanguageOnServerSaga);
	yield* takeEvery(clientClientOnlyActions.changeLanguage.type, changeLanguageOnClientSaga);
	yield* takeEvery(clientClientOnlyActions.globalError.type, processGlobalErrorSaga);
	yield* takeEvery(clientClientOnlyActions.showNotification.type, notificationSaga);
	yield* takeLeading(clientClientOnlyActions.playSound.type, playSoundSaga, browser);
	yield* takeLeading(clientClientOnlyActions.navigate.type, navigateChatsAndChannelsSaga);
	yield* takeLeading(clientClientOnlyActions.setChatFontSize.type, storeFontSizeSaga);
	yield* takeEvery(stopShortcutsOn, stopShortcutsOnModalOpenedSaga);
	yield* takeEvery(startShortcutsOn, restartShortcutsProcessingSaga);
	yield* takeEvery(clientClientOnlyActions.updateNetworkStatus, processNetworkStatusUpdateSaga);
	yield* takeEvery(clientClientOnlyActions.updateBackendConnectionStatus, processBackendConnectionStatusUpdateSaga);
	yield* takeEvery(clientClientOnlyActions.toggleUserInfoArea, toggleUserInfoAreaSaga);
}

export default clientSagaWatcher;
