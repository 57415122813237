import {call, put, select} from 'typed-redux-saga';

import {selectHasAnyActiveGuest} from '@messenger/core/src/Redux/Chats/Selectors/selectHasAnyActiveGuest';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import {resetChannelsMapSaga} from 'src/Redux/ChannelsMap/Sagas/resetChannelsMapSaga';

export const toggleGuestTypeFilterSaga = function* ({
	payload: guestTypeFilter,
}: ReturnType<typeof clientClientOnlyActions.toggleGuestTypeFilter>) {
	const currentGuestTypeFilter = yield* select(selectGuestTypeFilter);

	if (currentGuestTypeFilter === guestTypeFilter) {
		return;
	}

	yield* put(clientClientOnlyActions.setGuestTypeFilter(guestTypeFilter));

	const hasAnyActiveGuest = yield* select(selectHasAnyActiveGuest);

	if (
		(guestTypeFilter === EnumGuestType.ALL && currentGuestTypeFilter === EnumGuestType.CHANNEL && !hasAnyActiveGuest) ||
		currentGuestTypeFilter === EnumGuestType.ALL
	) {
		return;
	}

	yield* call(resetChannelsMapSaga);
};
