import {put, select} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';

import {selectCurrentGuestAttachmentId} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestAttachmentId';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export function* processRemoveEmptyAudioAttachmentSaga() {
	try {
		const attachmentId = yield* select(selectCurrentGuestAttachmentId);

		if (attachmentId && isMobile && ServiceFactory.env.isAppMobileEnabled()) {
			yield* put(attachmentClientOnlyActions.detach({attachmentId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processRemoveEmptyAudioAttachmentSaga'});
	}
}
