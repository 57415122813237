import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {put, select} from 'typed-redux-saga';
import {EnumMessageDirection} from 'cmd-control-client-lib';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectSentMessageKeys} from '@messenger/core/src/Redux/Messages/Selectors/selectSentMessageKeys';

const processRemovedMessageSaga = function* ({payload: {messageKey, direction}}: PayloadAction<TSswMessageType>) {
	try {
		if (_.isUndefined(messageKey) || direction === EnumMessageDirection.IN) {
			return;
		}

		const sentMessageKeys = yield* select(selectSentMessageKeys);

		if (!sentMessageKeys[messageKey]) {
			yield* put(messagesClientOnlyActions.removeSentMessageKey(messageKey));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processRemovedMessageSaga'});
	}
};

export default processRemovedMessageSaga;
