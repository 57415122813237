import _ from 'lodash';
import {ACTION} from 'cmd-control-client-lib';
import {call, select} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectIsStreamStartedFromCurrentInstance} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamStartedFromCurrentInstance';
import {selectRunningNonVoyeurChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningNonVoyeurChatIds';
import selectActiveVoyeurChatIds from '@messenger/core/src/Redux/Chats/Selectors/selectActiveVoyeurChatIds';

export function* navigateToAllChatsOnFirstGuestEnterSaga(action: ReturnType<typeof chatsClientOnlyActions.upsertMany>) {
	try {
		if (!isMobile || !ServiceFactory.env.isAppMobileEnabled()) {
			return;
		}

		const isStreamStartedFromCurrentInstance = yield* select(selectIsStreamStartedFromCurrentInstance);

		if (!isStreamStartedFromCurrentInstance) {
			return;
		}

		const newChatIds = _.chain(action.meta.commands).filter({action: ACTION.CMDC_CINIT}).map('params.chatID').value();

		if (_.size(newChatIds) === 0) {
			return;
		}

		const runningNonVoyeurChatIds = yield* select(selectRunningNonVoyeurChatIds);
		const runningVoyeurChatIds = yield* select(selectActiveVoyeurChatIds);
		const runningChatIds = _.merge(runningNonVoyeurChatIds, runningVoyeurChatIds);

		if (_.size(runningChatIds) === 0) {
			return;
		}

		if (_.chain(runningChatIds).difference(newChatIds).size().value() === 0) {
			const {goToChatMessages, isChatPage} = yield* getNavigationContext();

			if (!isChatPage(ALL_CHATS)) {
				yield* call(goToChatMessages, ALL_CHATS);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateToAllChatsOnFirstGuestEnterSaga'});
	}
}
