import {call, delay, put, race, select, take} from 'typed-redux-saga';
import {EnumSetTp} from 'cmd-control-client-lib';
import {AnyAction} from 'redux';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {selectIsOffline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOffline';

import stopWebRtcOutputStreamSaga from 'src/Redux/Stream/Sagas/stopWebRtcOutputStreamSaga';

const processRestartGoOnlineSaga = function* () {
	try {
		const isOffline = yield* select(selectIsOffline);

		if (!isOffline) {
			yield* put(streamClientToServerActions.stopSend());
			yield* call(stopWebRtcOutputStreamSaga, false);

			const [, action] = yield* race([
				delay(3000),
				take(
					({type, payload}: AnyAction) =>
						type === sessionServerToClientActions.sessionUpdated.type && _.get(payload, 'setTp') === EnumSetTp.OFFLINE,
				),
			]);

			if (!action) {
				return;
			}
		}

		yield* put(streamClientOnlyActions.goOnline());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processRestartGoOnlineSaga'});
	}
};

export default processRestartGoOnlineSaga;
