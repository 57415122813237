import {fork} from 'typed-redux-saga';

import chatsSagaWatcher from '@messenger/core/src/Redux/Chats/chatsSagaWatcher';
import notificationsSagaWatcher from '@messenger/core/src/Redux/Notifications/notificationsSagaWatcher';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import chatSummarySagaWatcher from '@messenger/core/src/Redux/ChatSummary/chatSummarySagaWatcher';
import liveSessionsSagaWatcher from '@messenger/core/src/Redux/LiveSessions/liveSessionsSagaWatcher';
import startRootSaga from '@messenger/core/src/Redux/startRootSaga';
import sagaMiddleware from '@messenger/core/src/Middleware/sagaMiddleware';
import {mediaSagaWatcher} from '@messenger/core/src/Redux/Media/mediaSagaWatcher';

import mediaDeviceSagaWatcher from 'src/Redux/MediaDevice/mediaDeviceSagaWatcher';
import contactNoteSagaWatcher from 'src/Redux/ContactNote/contactNoteSagaWatcher';
import cam2CamPreviewSagaWatcher from 'src/Redux/Cam2CamPreview/cam2CamPreviewSagaWatcher';
import channelsSagaWatcher from 'src/Redux/Channels/channelsSagaWatcher';
import clientSagaWatcher from 'src/Redux/Client/clientSagaWatcher';
import IntroductionSaga from 'src/Redux/Introduction/introductionSagaWatcher';
import messagesSagaWatcher from 'src/Redux/Messages/messagesSagaWatcher';
import previewSagaWatcher from 'src/Redux/Preview/previewSagaWatcher';
import feedbackSagaWatcher from 'src/Redux/Feedback/feedbackSagaWatcher';
import sessionSagaWatcher from 'src/Redux/Session/sessionSagaWatcher';
import speedTestWatcherSaga from 'src/Redux/SpeedTest/speedTestWatcherSaga';
import streamSagaWatcher from 'src/Redux/Stream/streamSagaWatcher';
import toyControlSagaWatcher from 'src/Redux/Toy/toyControlSagaWatcher';
import queryUserSagaWatcher from 'src/Redux/QueryUser/queryUserSagaWatcher';
import loginFormSagaWatcher from 'src/Redux/LoginForm/loginFormSagaWatcher';
import mediaPricesSagaWatcher from 'src/Redux/MediaPrices/mediaPricesSagaWatcher';
import attachmentSagaWatcher from 'src/Redux/Attachment/attachmentSagaWatcher';
import chatSagaWatcher from 'src/Redux/Chat/chatSagaWatcher';
import videoChatTestSagaWatcher from 'src/Redux/VideoChatTest/videoChatTestSagaWatcher';
import modelSettingsSagaWatcher from 'src/Redux/ModelSettings/modelSettingsSagaWatcher';
import mediaLinksSagaWatcher from 'src/Redux/MediaLinks/mediaLinksSagaWatcher';
import abuseReportSagaWatcher from 'src/Redux/AbuseReport/abuseReportSagaWatcher';
import ticketShowSagaWatcher from 'src/Redux/TicketShow/ticketShowSagaWatcher';
import groupsSagaWatcher from 'src/Redux/Groups/groupsSagaWatcher';
import channelGroupsSagaWatcher from 'src/Redux/ChannelGroups/channelGroupsSagaWatcher';
import routingSagaWatcher from 'src/Redux/Routing/routingSagaWatcher';
import channelGroupsPurchaseAbilitySagaWatcher from 'src/Redux/ChannelGroupsPurchaseAbility/channelGroupsPurchaseAbilitySagaWatcher';
import {sipSagaWatcher} from 'src/Redux/Sip/sipSagaWatcher';
import {channelsMapSagaWatcher} from 'src/Redux/ChannelsMap/channelsMapSagaWatcher';
import {messageInputSagaWatcher} from 'src/Redux/MessageInput/messageInputSagaWatcher';

/**
 * @link https://words.thisishugo.com/how-to-pass-an-api-client-to-a-redux-saga-f35170356c53
 * @constructor
 */
function* rootSaga() {
	const uiContainer = ServiceFactory.uiContainer;

	yield* fork(clientSagaWatcher, uiContainer);
	yield* fork(queryUserSagaWatcher, uiContainer);
	yield* fork(toyControlSagaWatcher);
	yield* fork(loginFormSagaWatcher);
	yield* fork(sessionSagaWatcher);
	yield* fork(channelsSagaWatcher);
	yield* fork(feedbackSagaWatcher);
	yield* fork(speedTestWatcherSaga);
	yield* fork(chatsSagaWatcher);
	yield* fork(messagesSagaWatcher, uiContainer);
	yield* fork(messageInputSagaWatcher);
	yield* fork(IntroductionSaga);
	yield* fork(mediaDeviceSagaWatcher);
	yield* fork(streamSagaWatcher);
	yield* fork(contactNoteSagaWatcher);
	yield* fork(cam2CamPreviewSagaWatcher);
	yield* fork(previewSagaWatcher);
	yield* fork(attachmentSagaWatcher);
	yield* fork(mediaPricesSagaWatcher);
	yield* fork(chatSagaWatcher);
	yield* fork(videoChatTestSagaWatcher);
	yield* fork(modelSettingsSagaWatcher);
	yield* fork(notificationsSagaWatcher);
	yield* fork(mediaSagaWatcher);
	yield* fork(mediaLinksSagaWatcher);
	yield* fork(abuseReportSagaWatcher);
	yield* fork(ticketShowSagaWatcher);
	yield* fork(groupsSagaWatcher);
	yield* fork(channelGroupsSagaWatcher);
	yield* fork(routingSagaWatcher);
	yield* fork(chatSummarySagaWatcher);
	yield* fork(liveSessionsSagaWatcher);
	yield* fork(channelGroupsPurchaseAbilitySagaWatcher);
	yield* fork(sipSagaWatcher);
	yield* fork(channelsMapSagaWatcher);
}

const sagaRunner = startRootSaga(sagaMiddleware, rootSaga);

export default sagaRunner;
