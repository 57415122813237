import {call, put, select} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import {isMobile} from 'react-device-detect';

import {selectStreamConnectOptions} from '@messenger/core/src/Redux/Stream/Selectors/selectStreamConnectOptions';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {DeviceTypes, TRequestMediaDevicePermissionsPayload} from '@messenger/core/src/Redux/MediaDevice/slice';
import WebRtcConfigVM from '@messenger/core/src/Redux/Stream/WebRtcConfigVM';
import {selectShouldRestartStream} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectShouldRestartStream';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChosenMediaDevices} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectChosenMediaDevices';
import {selectIsMicDisabled} from '@messenger/core/src/Redux/MediaDevice/Selectors/isMicDisabled';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {selectVideoFacingMode} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectVideoFacingMode';

function* requestMediaDevicePermissionsSaga(action: PayloadAction<TRequestMediaDevicePermissionsPayload>) {
	const i18next = ServiceFactory.i18n;
	const isMobileApp = isMobile && ServiceFactory.env.isAppMobileEnabled();

	try {
		const isMicDisabled = yield* select(selectIsMicDisabled);
		const selectedDevices = yield* select(selectChosenMediaDevices);
		const streamConnectionOptions = yield* select(selectStreamConnectOptions);
		const api = ServiceFactory.webRtcApi;

		if (!streamConnectionOptions.webRTCConfig) {
			ServiceFactory.logService.error(
				'Not possible to requestMediaDevicePermissions without WebRTCConfig',
				streamConnectionOptions,
			);

			return;
		}

		let webRtcConfig = streamConnectionOptions.webRTCConfig;

		const isFacingModeAllowed = isMobileApp && webRtcConfig?.video && !action.payload.ignoreVideoFacingMode;
		const videoFacingMode = yield* select(selectVideoFacingMode);

		if (isFacingModeAllowed) {
			webRtcConfig = {
				...webRtcConfig,
				video: {...webRtcConfig.video, facingMode: {exact: videoFacingMode}},
			};
		}

		let constraints = ServiceFactory.webRtcApi.devicesToConstraints(
			new WebRtcConfigVM(webRtcConfig),
			action.payload.isMicrophoneRequired,
			selectedDevices.videoinput,
			action.payload.isMicrophoneRequired ? selectedDevices.audioinput : undefined,
		);

		if (action.payload.ignoreVideoConstraints) {
			constraints = {
				...constraints,
				video: isFacingModeAllowed ? {facingMode: videoFacingMode} : true,
			};
		}

		yield* call([api, api.requestPermissions], constraints);
		yield* put(
			mediaDeviceClientOnlyActions.requestMediaDevicePermissionsReceived({
				isMicAvailable: action.payload.isMicrophoneRequired,
			}),
		);

		if (!action.payload.isMicrophoneRequired) {
			yield* put(mediaDeviceClientOnlyActions.requestMicPermissionFailed(i18next.t('config:microphone.denied')));
		}

		yield* put(mediaDeviceClientOnlyActions.setIsMicDisabled(isMicDisabled));

		const selectedDevicesUpdate = {
			[DeviceTypes.VIDEO_INPUT]: selectedDevices[DeviceTypes.VIDEO_INPUT],
			[DeviceTypes.AUDIO_INPUT]: selectedDevices[DeviceTypes.AUDIO_INPUT],
		};

		const selectedVideoDevice = api.videoTrackSettings?.deviceId;

		if (selectedVideoDevice) {
			selectedDevicesUpdate[DeviceTypes.VIDEO_INPUT] = selectedVideoDevice;
		}

		const selectedAudioDevice = api.audioTrackSettings?.deviceId;

		if (selectedAudioDevice) {
			selectedDevicesUpdate[DeviceTypes.AUDIO_INPUT] = selectedAudioDevice;
		}

		yield* put(
			mediaDeviceClientOnlyActions.setChosenMediaDevices({
				mediaDevices: selectedDevicesUpdate,
			}),
		);

		const shouldRestartStream = yield* select(selectShouldRestartStream);

		if (shouldRestartStream) {
			yield* put(streamClientOnlyActions.startWebRTCStream());
			yield* put(mediaDeviceClientOnlyActions.setShouldRestartStream(false));
		}
	} catch (e) {
		if (e instanceof Error || e instanceof OverconstrainedError) {
			if (e.name === 'NotFoundError' || e.name === 'DevicesNotFoundError') {
				yield* put(streamClientOnlyActions.startExternalToolStream());
			}
			// OverconstrainedError doesn’t support by Firefox. But it has MediaStreamError with name OverconstrainedError
			else if (e.name === 'OverconstrainedError') {
				yield* put(
					mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
						...action.payload,
						ignoreVideoConstraints: true,
					}),
				);
			} else if (isMobileApp && e instanceof OverconstrainedError && !action.payload.ignoreVideoFacingMode) {
				yield* put(
					mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
						...action.payload,
						ignoreVideoFacingMode: true,
					}),
				);
			} else if ('constraint' in e) {
				yield* put(
					mediaDeviceClientOnlyActions.requestMediaDevicePermissionsFailed(
						i18next.t('config:tabs.externaldevice.webcam.constraints.error'),
					),
				);
			} else if (e.name === 'NotReadableError' && !action.payload.ignoreVideoConstraints) {
				yield* put(
					mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
						...action.payload,
						ignoreVideoConstraints: true,
					}),
				);
			} else if (action.payload.isMicrophoneRequired) {
				yield* put(
					mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
						...action.payload,
						isMicrophoneRequired: false,
					}),
				);
			} else {
				yield* put(mediaDeviceClientOnlyActions.requestMediaDevicePermissionsFailed(e.toString()));
				ServiceFactory.logService.warn(`Failed to get camera permissions`, {
					saga: 'requestMediaDevicePermissionsSaga',
					error: e,
					payload: action.payload,
				});
			}
		} else {
			ServiceFactory.logService.error(`Error is not instanceof Error: ${e}`, {
				saga: 'requestMediaDevicePermissionsSaga',
				error: e,
			});
		}
	}

	yield* put(mediaDeviceClientOnlyActions.requestMediaDevicesList());
}

export default requestMediaDevicePermissionsSaga;
